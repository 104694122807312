import React from "react";
import PropTypes from 'prop-types';
import { Link, withRouter } from "react-router-dom";

import { Icon } from "@rdcs/dap-front-library";

const Content = ({ user }) => (
    <main className={user ? "" : "credentialsMain__container"}>
        <div className={user ? "" : "credentialsMain"}>
            <Link to="/connexion" className={user ? "" : "credentialsMain__back"}>
                <Icon name="arrow-left" />
            </Link>
            <h1 className={`credentials__title ${user && "mt-2 mb-2"}`}>
                Conditions d'utilisation
            </h1>
            <div className="cguContent">
                <p>
                    Vous êtes autorisés à utiliser la plateforme « Diabetics Adherence Platform » éditée par le
                    groupe Roche (la Plateforme) à titre gratuit uniquement pour utiliser les fonctionnalités
                    fournies auxquelles vous avez souscrit ou qui vous sont fournies. En accédant à la
                    Plateforme, vous acceptez l’intégralité des présentes dispositions.
                </p>
                <ul>
                    <li>
                        <u>Contact</u>
                        <u />
                    </li>
                </ul>
                <p>
                    Timkl SAS est situé au 565 rue Aristide Bergès – 38330 Montbonnot-Saint-Martin et peut être
                    contacté par e-mail : timkl.info@timkl.fr .
                </p>
                <ul>
                    <li>
                        <u>Fonctionnalités et interopérabilité</u>
                        <u />
                    </li>
                </ul>
                <p>
                    La Plateforme est mise à disposition de l’utilisateur gratuitement en langue française. Elle
                    est fournie via internet (SaaS) et a vocation à faciliter la conformité des soins fournis au
                    patient diabétique aux recommandations de la Haute Autorité de Santé ou HAS (agence publique
                    indépendante française créée du 13 août 2004 relative à l’Assurance maladie). Cette
                    plateforme numérique aide les professionnels de santé et les patients diabétiques à assurer
                    le suivi de la conformité avec ces recommandations, à organiser et vérifier le nombre de
                    mesures de glycémie et à maintenir une communication directe entre les professionnels de
                    santé et les patients diabétiques sur la base des stratégies de soin définies par la HAS.
                </p>
                <p>
                    La Plateforme est intégrée avec l’outil Gigya de gestion des identités et avec la solutoin
                    Accu-chek SmartPix Online uniquement pour permettre la collecte du nombre de mesures de
                    glycémie réalisées par un patient sur les appareils compatibles (Accu-chek Guide et Mobile)
                    et le calcul d’une moyenne de ces mesures.
                </p>
                <p>
                    Dans ce contexte, des mesures de sécurité sont mises en place conformément aux référentiels
                    de la Politique Générale de Sécurité des Systèmes d’Information de Santé en particulier par
                    l’utilisation d’un identifiant national, l’authentification forte et la traçabilité des
                    actes sur la Plateforme.
                </p>
                <p>La Plateforme est composée de trois interfaces.</p>
                <p>
                    o <u>L’interface « Timkl » </u>
                    <u />
                </p>
                <p>
                    Elle est utilisée par un nombre restreint de personnes soumises à une stricte obligation de
                    confidentialité et de secret professionnel. Elle comprend cinq pages : (i) un tableau de
                    bord précisant le nombre de nouveaux professionnels de santé et patients par mois ; (ii) la
                    gestion des recommandations pour éditer ces dernières ; (iii) un espace modérateur
                    permettant l’affichage de la liste des professionnels de santé et la désactivation de
                    certains profils si nécessaire ; (iv) un espace facilitateur permettant l’affichage de la
                    liste des patients ainsi que leur statut et leur date de dernière activité ; (v) un espace
                    de configuration / paramétrage.
                </p>
                <p>
                    L’administrateur (salarié Timkl) peut accéder aux cinq pages ; le modérateur (salarié Timkl)
                    peut accéder à l’espace modérateur ; les facilitateurs (infirmières partenaires Timkl
                    assistées de leur équipe) peuvent accéder à l’espace facilitateur.
                </p>
                <p>
                    o <u>L’interface « professionnels de santé »</u>
                    <u />
                </p>
                <p>
                    La Plateforme fournit aux professionnels de santé utilisateurs les fonctionnalités suivantes
                    : (i) liste des patients suivis et inscrits ; (ii) tableau de bord du statut patient
                    (conformité aux recommandations y compris examens, consultations et information, indice
                    HbA1c, nombre de mesures de glycémie) ; (iii) alertes concernant les prochaines visites de
                    spécialistes, conformité des mesures de glycémie et indice HbA1C) ; (iv) outil de
                    communication entre professionnels et patients, pharmaciens, infirmières et autres
                    professionnels ; (v) calendrier des prochains rendez-vous entre professionnels de santé et
                    patients.
                </p>
                <p>
                    o <u>L’interface « patient »</u>
                    <u />
                </p>
                <p>
                    Les patients seront en mesure de suivre la conformité de leurs soins grâce aux informations
                    suivantes : (i) alertes concernant les prochaines visites de spécialistes, conformité des
                    mesures de glycémie et indice HbA1C ; (ii) calendrier des prochains rendez-vous avec des
                    professionnels de santé ; (iii) outil de communication avec les professionnels de santé.
                </p>
                <ul>
                    <li>
                        <u>Paramétrage de navigateurs recommandés</u>
                        <u />
                    </li>
                </ul>
                <p>
                    Les navigateurs n’étant pas sécurisés par défaut, les recommandations ci-après doivent être
                    suivies pour minimiser le risque que votre appareil soit compromis :
                </p>
                <p>- Veiller à effectuer les mises à jour du navigateur,</p>
                <p>- Autoriser les mises à jour automatiques du navigateur,</p>
                <p>- Bloquer les pop-ups, les plugs in et les sites de phishing,</p>
                <p>- Interdire au navigateur le stockage des mots de passe,</p>
                <p>- Interdire les cookies tiers.</p>
                <p>Paramétrages spécifiques :</p>
                <p>- Firefox: installer l’add-on uBlock Origin,</p>
                <p>- Safari : désactiver Java,</p>
                <p>- Internet Explorer : mettre en place des zones de sécurité.</p>
                <p>En outre, les comportements suivants doivent être adoptés :</p>
                <p>- Ne pas cliquer sur des liens inconnus ou non sollicités,</p>
                <p>- Ne pas ouvrir de pièces jointes non attendues,</p>
                <p>
                    - Ne pas télécharger de fichiers, programmes ou outils sauf à avoir la certitude de leur
                    provenance et caractère sécurisé.
                </p>
                <ul>
                    <li>
                        <u>Restrictions d’utilisation</u>
                        <u />
                    </li>
                </ul>
                <p>Vous n’êtes pas autorisé, directement ou indirectement, à</p>
                <p>
                    (i) Accéder ou utiliser la Plateforme pour héberger ou transférer tout contenu, donnée et
                    autre information qui serait illégal ou en violation de droits de tiers tels que droits de
                    propriété intellectuelle ou vie privée, ou qui violerait d’une quelconque manière une loi
                    applicable ;
                </p>
                <p>
                    (ii) Copier, traduire, réaliser des œuvres dérivées, désassembler, décompiler, faire de la
                    rétro-ingénierie ou tenter d’une autre manière de découvrir le code source ou les idées
                    sous-jacentes ou les algorithmes inclus dans les logiciels ou autres systèmes utilisés pour
                    fournir la Plateforme, sauf autorisation légale, ou supprimer tout titre ou marque, mention
                    de droits d’auteur ou de restrictions d’utilisations sur les systèmes, logiciels ou autre
                    élément utilisé pour fournir la Plateforme ;
                </p>
                <p>
                    (iii) Distribuer, vendre, sous-licencier, louer, mettre à disposition ou utiliser la
                    Plateforme (en tout ou partie) dans des contrats de temps partagé, hébergement, fourniture
                    de service ou finalités similaires ;
                </p>
                <p>
                    (iv) Supprimer toute identification de produit, mention de propriété, de droit d’auteur ou
                    toute autre mention contenue dans la Plateforme ;
                </p>
                <p>
                    (v) Utiliser tout équipement, appareil, logiciel, ou autre élément pour contourner ou
                    supprimer toute forme de clé produit, numéro de série, ou protection contre la copie ou
                    utiliser la Plateforme en lien avec un code d’autorisation, clé produit, numéro de série, ou
                    autre appareil de protection contre la copie non fourni dans le cadre des présentes ;
                </p>
                <p>(vi) Utiliser la Plateforme pour accéder à une base de données hors de la Plateforme ;</p>
                <p>
                    (vii) Accéder ou utiliser la Plateforme pour développer un produit concurrent ou une
                    application dans la Plateforme ;
                </p>
                <p>
                    (viii) Rendre tout résultat de benchmarking pour toute application logicielle disponible à
                    des tiers ;
                </p>
                <p>
                    (ix) Accéder à ou utiliser la Plateforme de manière à éviter l’application de frais de
                    licence ou d’excéder des limitations d’usage ou quotas ;
                </p>
                <p>
                    (x) Accéder ou utiliser la Plateforme d’une manière créant des risques de sécurité ou qui
                    pourrait autrement impacter négativement la Plateforme ;
                </p>
                <p>
                    (xi) Télécharger ou distribuer des fichiers contenant des virus ou autre code dangereux ou
                    autre logiciel similaire qui pourrait accéder à ou endommager le fonctionnement de la
                    Plateforme ou d’équipements ;
                </p>
                <p>
                    (xii) Contourner ou divulguer les noms d’utilisateurs, mots de passes et autres données
                    d’accès ou informations d’authentification, interférer avec ou intervenir sur tout contrôle
                    de sécurité.
                </p>
                <ul>
                    <li>
                        <u>Licence</u>
                        <u />
                    </li>
                </ul>
                <p>
                    Les règles concernant la propriété industrielle ou les droits d’auteur sont celles en
                    vigueur actuellement en France, et s’appliquent au contenu mis à disposition. L’ensemble des
                    marques mentionnées sur la Plateforme sont détenues à titre propriétaire ou par voie de
                    licence par une des sociétés du groupe Roche. L’ensemble des éléments de la Plateforme
                    (textes, photos, maquettes, logos…) est couvert par des droits de propriété intellectuelle.
                    [La Plateforme a été développée par le groupe Roche avec la société Sooyoos SARL]. Toute
                    reproduction, transmission, diffusion ou autre forme d’utilisation des informations ou
                    éléments diffusés sur la Plateforme dépassant le cadre d’une utilisation à des fins
                    personnelles est formellement interdite.
                </p>
                <p>
                    Est accordée par les présentes à l’utilisateur une licence ne pouvant faire l’objet d’une
                    sous-licence, incessible, non-exclusive, pendant la durée d’utilisation de la Plateforme et
                    uniquement si cette utilisation est conforme à la documentation fournie. L’utilisateur
                    pourra utiliser les données accessibles dans la Plateforme pour ses propres besoins tels
                    qu’autorisés par les présentes. Si l’utilisateur reçoit, ou crée, des mots de passe ou
                    autres identifiants d’accès, il ne pourra pas permettre à des tiers d’utiliser lesdits mots
                    de passe/identifiants.
                </p>
                <ul>
                    <li>
                        <u>Confidentialité</u>
                        <u />
                    </li>
                </ul>
                <p>
                    Toutes les informations divulguées comme confidentielles, ou que l’utilisateur devrait
                    raisonnablement considérer comme étant confidentielles (y compris la totalité des
                    informations contenues dans la Plateforme), constitueront les « Informations confidentielles
                    ». Les Informations confidentielles ne seront divulguées à aucun tiers ni utilisées à toute
                    fin autre que celles du présent Contrat. Les restrictions susmentionnées ne s’appliqueront
                    pas aux données ou à toute autre information qui est disponible publiquement sans que cela
                    résulte d’une faute commise par l’utilisateur.
                </p>
                <ul>
                    <li>
                        <u>Responsabilité </u>
                        <u />
                    </li>
                </ul>
                <p>
                    Le contenu mis à disposition contient des éléments et informations qui sont en conformité
                    avec la législation en vigueur en France. Le groupe Roche n’assume aucune responsabilité en
                    ce qui concerne l'accès à des informations qui pourraient ne pas être compatibles avec une
                    législation, réglementation, ou l'usage en vigueur hors de France.
                </p>
                <p>
                    Les utilisateurs reconnaissent et acceptent que l’accès à d’autres sites vers lesquels la
                    Plateforme renvoie par le biais d’un lien, ainsi que l’utilisation de leur contenu, se
                    fassent sous leur entière responsabilité. Les entités du groupe Roche ne pourront voir leur
                    responsabilité engagée de quelque façon que ce soit en cas de dommage matériel ou
                    immatériel, direct ou indirect, notamment en cas de perte de bénéfices ou de données,
                    consécutifs ou non liés à l’accès, l’utilisation ou l’impossibilité d’utiliser tout site
                    vers lequel cette Plateforme renvoie, ou encore d’une quelconque erreur ou omission dans le
                    contenu. Les liens vers des sites de sociétés tierces sont fournis à titre indicatif
                    uniquement et le groupe Roche n’émet pas d’opinion sur le contenu des pages vers lesquelles
                    la Plateforme renvoie par le biais d’un lien et n’assume aucune responsabilité concernant
                    les informations contenues sur ces pages.
                </p>
                <p>
                    Les garanties légales de conformité ou relatives aux vices cachés relatives à la vente ne
                    sont pas applicables à la fourniture à titre gratuit de la Plateforme. De plus, aucune
                    garantie n’est donnée quant au fait que : les fonctionnalités satisferont l’intégralité des
                    besoins de l’utilisateur ; la Plateforme fonctionnera normalement lorsqu’elle sera installée
                    ou utilisée en association avec d’autres applications, logiciels ou autres composants
                    installés ou synchronisés avec l’équipement de l’utilisateur ; la Plateforme sera exempte
                    d’erreur ou de dysfonctionnement.
                </p>
                <ul>
                    <li>
                        <u>Cookies</u>
                        <u />
                    </li>
                </ul>
                <p>
                    Nous utilisons des cookies sur la Plateforme. Un cookie est un fichier texte unique qu’un
                    site internet peut transmettre à votre logiciel de navigation. Les cookies permettent à un
                    site internet d’identifier un navigateur afin, par exemple, d’activer un mécanisme
                    d’auto-connexion. Nous utilisons des cookies afin de collecter des informations relatives
                    aux préférences et à la session de l’utilisateur.
                </p>
                <p>Nous pouvons utiliser des cookies pour différentes fins, notamment les suivantes :</p>
                <p>
                    o <u>Cookies nécessaires sur le plan opérationnel. </u>
                    <u />
                </p>
                <p>
                    Cookies de session : nous utilisons des cookies pour conserver les informations de session
                    pendant la durée de la session active d’un utilisateur. Les cookies de session sont
                    nécessaires à l’utilisation sécurisée de la Plateforme par l’utilisateur et pour distinguer
                    les accès à la Plateforme à partir d’un navigateur unique.
                </p>
                <p>
                    Cookies persistants : nous utilisons des cookies nous permettant d’assurer la sécurité de
                    votre authentification sur la Plateforme. Ces cookies sont stockés de façon permanente sur
                    votre navigateur jusqu’à leur expiration ou jusqu’à ce que vous les supprimiez.
                </p>
                <p>
                    o <u>Mesures d’audience. </u>
                    <u />
                </p>
                <p>
                    Pendant certaines visites, nous sommes susceptibles d’utiliser des cookies de mesure et de
                    collecte d’informations de session, y compris le temps de réponse des pages, les erreurs de
                    téléchargement, le temps passé sur certaines pages et les informations d’interaction entre
                    pages afin de mieux comprendre quelle utilisation est faite de la Plateforme de façon à
                    pouvoir l’améliorer. Ces données ne seront pas reliées à vous personnellement et les
                    rapports de résultats seront anonymisés par agrégation. Ce traitement sera actif uniquement
                    sous réserve d’un consentement distinct sauf exception permise par la Commission Nationale
                    de l’Informatique et des Libertés.
                </p>
                <ul>
                    <li>
                        <u>Données personnelles</u>
                        <u />
                    </li>
                </ul>
                <p>
                    o <u>Finalité, base légale, durée de collecte des données patients</u> <u />
                </p>
                <p>Les données contenues dans la plateforme ont vocation à permettre :</p>
                <p>
                    · Conformément à nos obligations légales,{" "}
                    <strong>
                        <u>de vous identifier</u>
                    </strong>{" "}
                    en tant que patient pour la durée de votre compte augmentée d’un an (a) via votre numéro de
                    sécurité sociale lors de la création de votre compte permettant l’import de données de
                    contact si vous êtes déjà présent dans les solutions Roche et (b) via votre login lorsque
                    vous accédez sur la plateforme.
                </p>
                <p>
                    · Conformément à nos obligations légales,{" "}
                    <strong>
                        <u>d’assurer la sécurité des actions</u>
                    </strong>{" "}
                    sur la plateforme en conservant les logs pendant cinq ans à compter de la collecte.
                </p>
                <p>
                    · Conformément aux conditions d’utilisation de la Plateforme et dans la mesure nécessaire à
                    l’administration des soins,{" "}
                    <strong>
                        {" "}
                        <u>de générer et suivre votre parcours de soin</u>
                    </strong>
                    , incluant une communication avec vous par téléphone ou e-mail par l’infirmière
                    coordinatrice Timkl ainsi qu’un partage avec les professionnels à qui vous ouvrez ces accès,
                    pour la durée de votre compte suivie d’un archivage de vos données pendant 10 ans.
                    <strong />
                </p>
                <p>
                    · Uniquement dans le cadre de notre intérêt légitime d’amélioration des services et avec
                    votre consentement qui vous sera demandé via un « bandeau cookies » en cas d’accès non
                    nécessaire à votre appareil,{" "}
                    <strong>
                        <u>de générer des statistiques agrégées</u>
                    </strong>{" "}
                    quant aux actions techniques sur la plateforme, pour une durée n’excédant pas quelques
                    dizaines de mois.
                    <strong />
                </p>
                <p>
                    · Uniquement si vous donnez votre consentement explicite et facultatif quant à l’utilisation
                    de vos données médicales et d’utilisation de la plateforme, d
                    <strong>
                        e <u>réaliser des études et recherches</u>{" "}
                    </strong>
                    relatives au traitement du diabète et à l’organisation des soins pour évaluation vis-à-vis
                    des autorités publiques françaises, pour une durée n’excédant pas quelques dizaines de mois.
                </p>
                <p>
                    o<u>Finalité, base légale, durée de collecte des données des professionnels</u>
                    <u />
                </p>
                <p>
                    En tant que Professionnel de santé, vous êtes référencé dans une base publique et à ce
                    titre, votre identifiant nous permet de remplir automatiquement les données de votre profil.
                    Ces données ont vocation à vous permettre :
                </p>
                <ul>
                    <li>
                        <strong>
                            <u>de vous identifier</u>
                        </strong>
                        afin d’éviter tout doublon puis lorsque vous accédez et effectuez des actions sur la
                        plateforme (conformément à nos obligations légales) : vos données d’identification sont
                        conservées pour la durée d’activité de votre compte augmentée d’un an.
                    </li>
                    <li>
                        <strong>
                            <u>d’assurer la sécurité des actions</u>
                        </strong>
                        sur la plateforme (conformément à nos obligations légales) : les logs sont conservés
                        pendant cinq ans à compter de la collecte.
                    </li>
                    <li>
                        <strong>
                            <u>de gérer votre compte</u>
                        </strong>
                        , communiquer avec vous (y compris par contact téléphonique ou e-mail) et vous permettre
                        d’accéder au dossier des patients ayant accepté le partage de leurs données
                        (conformément au contrat avec Timkl): les données de votre compte sont conservées pour
                        la durée d’activité de votre compte puis archivées 10 ans sauf indication contraire de
                        votre part.
                        <strong />
                    </li>
                    <li>
                        <strong>
                            <u>De générer des statistiques</u>
                        </strong>
                        quant aux actions techniques sur la plateforme de façon non individuelle (uniquement
                        dans le cadre de notre intérêt légitime d’amélioration des services et avec votre
                        consentement qui vous sera demandé via un « bandeau cookies » en cas d’accès non
                        nécessaire à votre appareil): ces données seront traitées pour une durée n’excédant pas
                        quelques dizaines de mois.
                        <strong />
                    </li>
                </ul>
                <p>
                    o <u>Destinataires</u>
                    <u />
                </p>
                <p>
                    Pour pouvoir vous fournir nos services, nous collaborons régulièrement avec des tiers
                    internes ou externes au groupe Roche en Europe, par exemple des affiliées de Roche qui
                    appartiennent au groupe Roche/F. Hoffmann-La Roche Ltd. Les prestations d’authentification
                    sont réalisées par Gigya,
                    <a href="https://www.privacyshield.gov/participant?id=a2zt0000000TOBwAAO&status=Active">
                        un
                    </a>
                    service fourni par SAP Inc. en Irlande; les données de santé sont hébergées en Allemagne par
                    AWS (infrastructure) et en Espagne par Accenture (administration) qui sont
                    <a href="https://esante.gouv.fr/labels-certifications/hds/liste-des-herbergeurs-certifies">
                        certifiés à cette fin au regard des exigences du
                    </a>
                    Code de la Santé Publique. Nous pouvons partager les données traitées sur la Plateforme avec
                    les forces de l’ordre, organismes publics ou des tiers autorisés au sens du RGPD en réponse
                    à une demande en ce sens ayant fait l’objet d’une vérification, ainsi qu’avec les autorités
                    de santé françaises autorisées et la caisse d’assurance maladie.
                </p>
                <p>
                    o <u>Emplacement de stockage, sécurité</u>
                    <u />
                </p>
                <p>
                    Nous avons pris des mesures de sécurité techniques, physiques et administratives
                    raisonnables afin de garantir la sécurité des données à caractère personnel vous concernant,
                    y compris afin d’éviter leur perte, utilisation abusive, divulgation, altération, ou l’accès
                    non autorisé à celles-ci. Les données vous concernant seront chiffrées sur nos serveurs. Il
                    vous incombe de veiller à ce que les données à caractère personnel vous concernant soient
                    exactes et à ce que votre mot de passe soit conservé en lieu sûr et en toute
                    confidentialité, et ne soit pas communiqué à des tiers. Si vous installez un logiciel que
                    nous proposons sur vos appareils locaux, il vous appartient de vous assurer que les
                    appareils en question soient sûrs.
                </p>
                <p>
                    o <u>Protection de la vie privée des enfants</u>
                    <u />
                </p>
                <p>
                    La Plateforme est réservée aux adultes. Elle ne peut être utilisée par des mineurs que sous
                    la supervision de leurs parents ou équivalent.
                </p>
                <p>
                    o <u>Vos droits</u>
                    <u />
                </p>
                <p>
                    Vous avez le droit, conformément à la loi applicable en matière de protection des données,
                </p>
                <p>
                    - de demander des informations au sujet des données traitées vous concernant, et d’obtenir
                    une copie de ces données (droit d’accès) ;
                </p>
                <p>
                    - d’obtenir la rectification des données inexactes ou, selon les finalités du traitement, de
                    demander à compléter les données incomplètes (droit de rectification) ;
                </p>
                <p>
                    - d’obtenir la suppression des données à caractère personnel vous concernant, dans la mesure
                    où l’un des motifs prévus par la loi s’applique (droit à l’oubli) ;
                </p>
                <p>
                    - d’obtenir la limitation du traitement des données vous concernant, dans la mesure où les
                    exigences légales sont satisfaites (droit à la limitation du traitement) ;
                </p>
                <p>
                    - dans la mesure où les exigences légales sont satisfaites, de recevoir les données à
                    caractère personnel vous concernant que vous nous avez fournies dans un format structuré,
                    couramment utilisé et lisible par machine, et de transmettre ces données à un autre
                    responsable du traitement ou, lorsque cela est techniquement possible, d’obtenir que ces
                    données soient transmises (droit à la portabilité des données) ;
                </p>
                <p>- de définir des directives relatives au sort de ces données après la mort ; et</p>
                <p>
                    - de ne pas faire l’objet d’une prise de décision individuelle automatisée si les exigences
                    légales ne sont pas satisfaites. Aucune décision individuelle automatisée n’est prise sur la
                    Plateforme.
                </p>
                <p>
                    Vous avez en outre le droit de vous opposer, pour des raisons tenant à votre situation
                    particulière, et conformément à la loi applicable, à un traitement des données à caractère
                    personnel vous concernant fondé sur le motif que ce traitement est nécessaire à la
                    réalisation des intérêts légitimes poursuivis (droit d’opposition). Si votre consentement
                    est requis pour l’un des traitements opérés sur ou en lien avec la Plateforme, vous avez
                    également le droit de retirer votre consentement à tout moment sans que cela n’ait
                    d’incidence sur la licéité du traitement fondé sur votre consentement avant le retrait de
                    celui-ci.
                </p>
                <p>
                    Pour exercer vos droits, veuillez vous connecter à votre compte, sur lequel vous pouvez
                    gérer certaines de vos demandes. Vous pouvez également contacter Timkl à l’adresse :{" "}
                    <a href="mailto:dpo.timkl@timkl.fr">dpo.timkl@timkl.fr</a>. Si vous n’êtes pas satisfait(e)
                    de la façon dont Timkl gère les données vous concernant ou des réponses apportées à vos
                    demandes, vous pouvez contacter la Commission Nationale de l’Informatique et des Libertés.
                </p>
                <ul>
                    <li>
                        <u>Durée et résiliation</u>
                        <u />
                    </li>
                </ul>
                <p>
                    Sous réserve des dispositions du présent article, les conditions d’utilisation prennent
                    effet à compter de la date de votre acceptation, et demeureront en vigueur jusqu’à leur
                    résiliation. Vous pouvez mettre fin à votre accès et à votre utilisation de la Plateforme
                    sans motif et à tout moment. Il peut également être mis fin à votre accès et à votre
                    utilisation de la Plateforme à tout moment et sans motif, par notification préalable sous
                    réserve d’un préavis de quatorze (14) jours calendaires. L’expiration ou la résiliation des
                    conditions d’utilisation n’affectera pas celles qui, en raison de leur nature, sont appelées
                    à survivre à l’expiration ou la résiliation, comme notamment les clauses relatives à la
                    responsabilité, la confidentialité, la loi applicable et la juridiction compétente.
                </p>
                <ul>
                    <li>
                        <u>Modifications des Conditions d’Utilisation du Portail</u>
                        <u />
                    </li>
                </ul>
                <p>
                    Les conditions d’utilisation pourront être modifiées de manière ponctuelle. Lorsque de
                    telles modifications seront effectuées, sera rendue disponible une nouvelle version des
                    conditions d’utilisation sur la Plateforme, qui indiquera la date de la dernière révision.
                    Vous acceptez et prenez acte que votre utilisation de la Plateforme à compter de la date de
                    la dernière révision vaudra acceptation des modalités et conditions révisées.
                </p>
                <p>
                    Si l’une des stipulations des présentes était déclarée nulle ou non avenue pour quelque
                    motif que ce soit, les autres stipulations resteront pleinement en vigueur. Les Parties
                    devront se consulter pour remplacer la stipulation nulle ou non avenue par une nouvelle
                    stipulation, dont l’objectif et le sens se rapprocheront le plus possible de ceux de la
                    stipulation nulle et non avenue. Le non-exercice par l’une des Parties, ou tout retard dans
                    l’exercice de tout droit ou recours en vertu des présentes ne constituera pas une
                    renonciation à ce droit ou recours ; de même, tout exercice isolé ou partiel de tout droit
                    ou recours en vertu des présentes n’exclut pas tout autre exercice ou exercice ultérieur de
                    ce droit ou recours ou l’exercice de tout autre recours prévu par les présentes, par tout
                    document se rapportant aux présentes ou par la loi.
                </p>
                <ul>
                    <li>
                        <u>Divers</u>
                        <u />
                    </li>
                </ul>
                <p>
                    Les présentes pourront être transférées en tout ou en partie par Roche Diabetes Care France
                    à tout moment et à toute personne par tout moyen y compris cession de contrat ou de fonds ou
                    changement de contrôle, sans consentement préalable de l’Utilisateur sauf à ce qu’un tel
                    transfert soit susceptible d’engendrer une diminution des droits de ce dernier.
                </p>
                <p>
                    Les présentes sont soumises à la loi française. Les parties tenteront de trouver une
                    solution amiable en cas de litige. A défaut, le litige sera soumis aux tribunaux compétents.
                </p>
                <p>
                    o <u>Dispositions applicables aux utilisateurs patients</u>
                    <u />
                </p>
                <p>
                    L’inscription vaut accord à la fourniture gratuite de la Plateforme, de sorte que les règles
                    relatives au droit de rétractation ne sont pas applicables, étant entendu toutefois que
                    l’utilisateur peut se désinscrire à tout moment. La mise à disposition à l’utilisateur des
                    fonctionnalités vaut accord exprès pour la fourniture de la Plateforme.
                </p>
                <p>
                    o <u>Dispositions applicables aux utilisateurs professionnels</u>
                    <u />
                </p>
                <p>
                    ROCHE ET SES PRESTATAIRES FOURNIRONT LE SERVICE « EN L’ÉTAT » ET SANS GARANTIE DE QUELQUE
                    NATURE QUE CE SOIT, ET EXCLUENT PAR LES PRÉSENTES TOUTES LES GARANTIES EXPRESSES OU
                    IMPLICITES CONCERNANT LE SERVICE, LES DONNÉES ET TOUT AUTRE OBJET DU PRÉSENT CONTRAT, Y
                    COMPRIS NOTAMMENT LES GARANTIES DE QUALITÉ MARCHANDE, D’ADÉQUATION À UN USAGE PARTICULIER OU
                    DE NON-CONTREFAÇON. DANS LA MESURE PERMISE PAR LA LOI, EN AUCUN CAS ROCHE (OU SES CONCÉDANTS
                    OU AUTRES PRESTATAIRES) NE SERA RESPONSABLE À L’ÉGARD DU SERVICE, DES DONNÉES OU DE TOUT
                    AUTRE OBJET DU PRÉSENT CONTRAT, AU TITRE D’UN CONTRAT, DU FAIT D’UN DÉLIT, EN RAISON D’UNE
                    NÉGLIGENCE OU DE TOUTE AUTRE THÉORIE JURIDIQUE POUR TOUT DOMMAGE ACCESSOIRE, SPÉCIAL,
                    INDIRECT OU CONSÉCUTIF, PERTE DE BÉNÉFICES, DE DONNÉES, INTERRUPTION D’ACTIVITÉ, QUELLE
                    QU’EN SOIT LA CAUSE, MÊME SI ROCHE A ÉTÉ AVERTI DE LA POSSIBILITÉ DESDITS DOMMAGES, OU LE
                    COÛT DES SERVICES OU DONNÉES DE SUBSTITUTION. Dans tous les cas, le montant de la
                    responsabilité de ROCHE est strictement limité au remboursement du montant des sommes
                    effectivement perçues de la part de l’utilisateur.
                </p>
                <p>V.1 septembre 2019</p>
            </div>
        </div>
    </main>
);

Content.propTypes = {
    user: PropTypes.object,
};

export default React.memo(withRouter(Content));