import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";

import { connect } from "react-redux";
import Questions from "../../actions/questions";

import { PractitionerLayout, DefaultLoader } from "@rdcs/dap-front-library";

import API from "../../utils/API";
import { isLocalAPI } from "../../utils/utils";
import { Roles, getRoles } from "../../utils/TokenData";

import useState from "./useState";

const { tryFetchBreakScoreQuestions,tryFetchBreakScoreNoInsulinQuestions } = Questions;

const userNav = [
    {
        title: "Profil",
        path: "/mon-profil",
        icon: "datas"
    },
    {
        title: "Se déconnecter",
        path: "/deconnexion",
        icon: "close"
    }
];

const AppLayout = ({
    navActive,
    pageClass,
    auth,
    auth: {
        user: {
            roles
        }
    },
    breakScoreQuestions: breakScoreQuestionsProp,
    tryFetchBreakScoreQuestions,
    tryFetchBreakScoreNoInsulinQuestions,
    children,
    breadcrumbs
}) => {
    const [{
        loading,
        nav
    }, {
        load,
        addBreakScoreQuestionOnNav
    }] = useState();



    React.useEffect(() => {
        const breakScoreQuestionAllowedRoles = getRoles(Roles.ROLE_PHARMACIST, Roles.ROLE_NURSE, Roles.ROLE_DOCTOR);

        if (roles.some(role => breakScoreQuestionAllowedRoles.includes(role))) {
            tryFetchBreakScoreNoInsulinQuestions()
            tryFetchBreakScoreQuestions()
            .then((breakScoreQuestions) => {
                addBreakScoreQuestionOnNav(breakScoreQuestions);
            })
            .catch(load);
        } else {
            load();
        }

    }, []);

    return loading ? (
        <DefaultLoader />
    ) : (
        <PractitionerLayout
            nav={nav}
            userNav={userNav}
            user={auth.user}
            pageClass={pageClass}
            navActive={navActive}
            breadcrumbs={breadcrumbs}
            roles={roles}
            searchAction={val => {
                let pCancelable = null;
                API.request(
                    {
                        path: isLocalAPI
                            ? "/practitioner_search"
                            : "/dap-hcp-patient/api/v1/customers/patients/practitioner-search",
                        queryParams: {
                            val
                        },
                        token: auth.access_token
                    },
                    callback => (pCancelable = callback)
                );
                return pCancelable;
            }}
        >
            {children}
            <footer className="page__footer">
                <hr />
                <Link to="/cgu" className="page__footer--link">
                    CGU
                </Link>
            </footer>
        </PractitionerLayout>
    );
}

AppLayout.propTypes = {
    navActive: PropTypes.number,
    pageClass: PropTypes.string,
    auth: PropTypes.object.isRequired,
    breakScoreQuestions: PropTypes.arrayOf(PropTypes.shape({
        active: PropTypes.bool.isRequired,
    })).isRequired,
    tryFetchBreakScoreQuestions: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node)
    ]).isRequired,
    breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        link: PropTypes.string
    })),
};

const mapStateToProps = ({ auth, questions: { breakScoreQuestions } }) => ({ auth, breakScoreQuestions });

const mapDispatchToProps = {
    tryFetchBreakScoreQuestions, 
    tryFetchBreakScoreNoInsulinQuestions
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppLayout));
