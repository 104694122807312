import React from "react";
import PropTypes from "prop-types";
import { withRouter, useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import { Animated } from "react-animated-css";
import { Icon, Form, StepButtons, InputRadio, InputSymbol, DoctorButton, PractitionerButton, Gauge, IconButton } from "@rdcs/dap-front-library";

import LeftImage from "@rdcs/dap-front-library/static/images/illu_pharma.svg";
import API from "../../../../utils/API";

const eligibleOptions = [
    { label: "Oui", value: true },
    { label: "Non", value: false },
];

const formId = "break-score-questionnaire-step3-form";

const Step3 = ({ prevStep, isEligible, isLoading, accessToken, onSave, onDelete, score, isDoctor, patientId }) => {
    const history = useHistory();
    const eligible = React.useMemo(
        () =>
            isEligible
                ? {
                      state: "Éligible",
                      considered: "au dessus",
                      icon: "patient-eligible",
                      button: "Enregistrer",
                      buttonIcon: "check-circle",
                      formId: "patient-final-form",
                  }
                : {
                      state: "Non éligible",
                      considered: "en dessous",
                      icon: "patient-non-eligible",
                      button: "Enregistrer",
                      buttonIcon: "check-circle",
                      formId: "patient-final-form",
                  },
        [isEligible]
    );

    const [patientConfirm, setPatientConfirm] = React.useState(null);
    const [doctorNextStep, setDoctorNextStep] = React.useState(false);
    const [doctorNextStepAnswer, setDoctorNextStepAnswer] = React.useState(false);

    const onDeleteRef = React.useRef(true);

    const redirectToPatients = React.useCallback(() => {
        history.push("/mes-patients");
    }, []);

    const createPatient = React.useCallback(() => {
        history.push(`/mes-patients/ajouter/${patientId}`);
    }, []);

    const onSubmitCallback = React.useCallback(
        (form) => {
            onDeleteRef.current = false;

            onSave(form)
                .then(() => {
                    onDeleteRef.current = false;
                    if (isDoctor) {
                        setDoctorNextStep(true);
                    } else {
                        redirectToPatients();
                        toast.success("Le profil du nouveau patient potentiel a bien été complété.");
                    }
                })
                .catch(() => {
                    toast.error("Une erreur s'est produite lors de la finalisation du profil du nouveau patient potentiel.");
                });
        },
        [onSave]
    );

    React.useEffect(
        () => () => {
            if (onDeleteRef.current) {
                onDelete();
            }
        },
        [doctorNextStepAnswer]
    );

    const goNextDoctor = React.useCallback((form) => {
        if (form.question === "true") {
            createPatient();
        }
        if (form.question === "false") {
            redirectToPatients();
        }
    }, []);

    return (
        <Animated animationIn="fadeIn">
            <div className="breakScoreQuestionnaire__container__step3">
                <div className="breakScoreQuestionnaire__container__step3--leftImage">
                    <img src={LeftImage} />
                </div>
                <div>
                    {doctorNextStep ? (
                        <section className="mt-4">
                            <Form onSubmit={goNextDoctor} id="doctorNextStepForm">
                                <p>Le dossier du patient a bien été créé. Souhaitez-vous définir le parcours de soin dès maintenant ?</p>
                                <InputRadio options={eligibleOptions} name="question" onChange={() => setDoctorNextStepAnswer(true)} />

                                <div className="txtRight mt-4">
                                    <IconButton
                                        className="button__primaryButton"
                                        type="submit"
                                        icon="arrow-right"
                                        right
                                        disabled={doctorNextStepAnswer ? false : true}
                                    >
                                        Enregistrer
                                    </IconButton>
                                </div>
                            </Form>
                        </section>
                    ) : (
                        <div className="breakScoreQuestionnaire__container__step3--rightForm">
                            <div>
                                <div className="form__group">
                                    <p className="title-4 mb-4">Au vu des réponses, le patient se situe {eligible.considered} du seuil d’éligibilité.</p>
                                </div>
                                <Gauge value={score > 100 ? 100 : Math.round(score)} info="Score du patient" legend="Seuil d’éligibilité" />
                                <section className="mt-4">
                                    <p>Souhaitez-vous, en accord avec le patient, poursuivre son intégration dans le programme AProDia ?</p>
                                    <InputRadio options={eligibleOptions} onChange={(val) => setPatientConfirm(val === eligibleOptions[0].value.toString())} />
                                </section>
                                {patientConfirm && (
                                    <section className="mt-4">
                                        <p className="title-3">
                                            Merci de remplir les champs suivants pour que le patient soit recontacté par un facilitateur Timkl.
                                        </p>
                                        <Form onSubmit={onSubmitCallback} id={formId}>
                                            <div className="grid">
                                                <InputSymbol
                                                    type="tel"
                                                    label="N° de téléphone du patient"
                                                    placeholder="→ Saisissez le numéro du patient"
                                                    required
                                                    name="phoneNumber"
                                                    inputClassName="col-sm-30 col-xs-100 not-floating-important"
                                                    symbol={<Icon name="phone" size={16} />}
                                                />
                                            </div>
                                            <div className="grid mb-4">
                                                <InputSymbol
                                                    type="email"
                                                    label="Adresse email du patient"
                                                    placeholder="→ Saisissez l’adresse email du patient"
                                                    name="email"
                                                    inputClassName="col-sm-50 col-xs-100 not-floating-important"
                                                    symbol={<Icon name="email" size={18} />}
                                                    required
                                                />
                                            </div>
                                            {isDoctor ? (
                                                <PractitionerButton
                                                    name="pointOfContact"
                                                    searchAction={(params) => API.searchInRpps(accessToken, params)}
                                                    icon="stethoscope"
                                                    secondary
                                                    labels={{
                                                        title: "Voulez-vous sélectionner ce référent de proximité",
                                                        buttonModal: "Identité du référent de proximité (facultatif)",
                                                        buttonSave: "Sélectionner",
                                                    }}
                                                    buttonClassName="timkl__color--blue-important"
                                                />
                                            ) : (
                                                <DoctorButton
                                                    name="practitioner"
                                                    searchAction={(params) => API.searchInRpps(accessToken, params)}
                                                    icon="stethoscope"
                                                    secondary
                                                    buttonClassName="timkl__color--blue-important"
                                                    required
                                                />
                                            )}
                                        </Form>
                                    </section>
                                )}
                            </div>
                            <StepButtons
                                prevStep={prevStep}
                                isLoadingNext={isLoading}
                                prevButtonClassName="text-uppercase"
                                nextButtonClassName={""}
                                isLast
                                lastIsLeft
                                validIcon={eligible.buttonIcon}
                                validText={eligible.button}
                                secondary={!isEligible}
                                form={formId}
                                onClickNext={patientConfirm ? null : redirectToPatients}
                                disabledNext={patientConfirm === null}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Animated>
    );
};

Step3.propTypes = {
    prevStep: PropTypes.func.isRequired,
    isEligible: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    accessToken: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    score: PropTypes.string.isRequired,
    isDoctor: PropTypes.bool.isRequired,
    patientId: PropTypes.number.isRequired,
};

export default withRouter(Step3);
