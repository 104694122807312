import React from "react";
import PropTypes from 'prop-types';
import { Redirect, Link, withRouter, useLocation } from "react-router-dom";

import { connect } from "react-redux";

import { PractitionerLayoutAnonymous, PractitionerLayoutAsideAnonymous, redirectUrlAfterAuthPath } from "@rdcs/dap-front-library";

import { LOGIN_URL } from "../../utils/utils";
import history from "../../config/history";

const removeError = () => {
    // Remove error when click on CGU, Sign in or Log in
    history.replace({ userDisabled: null });
};

const LoginScreen = ({ auth: { user } }) => {
    const location = useLocation();

    React.useEffect(() => {
        document.title = "Timkl - Connexion";
    }, []);

    return user === null ? (
        <PractitionerLayoutAnonymous>
            <div className="grid credentials__container">
                <PractitionerLayoutAsideAnonymous />
                <main className="col-sm-50 credentialsMain__container">
                    <div className="credentialsMain">
                        <div className="credentials__title">
                            Connectez-vous à <br />
                            Diabète Adhérence Plateforme
                        </div>
                        {location.state && location.state.userDisabled && (
                            <div className="bgError radius shadow mt-4 mb-2 pt-2 pl-3 pb-2 pr-3">
                                {location.state.userDisabled}
                            </div>
                        )}
                        <div>
                            <a
                                onClick={() => removeError()}
                                href={LOGIN_URL}
                                className="button button__primaryButton mx-0"
                            >
                                S'identifier
                            </a>
                        </div>
                    </div>
                    <div className="credentialsFooter">
                        <div className="credentialsFooter__register mr-3">
                            <div>
                                <strong>Pas encore inscrit ?</strong> <br />
                                Accompagnez facilement vos patients diabétiques en appliquant les
                                recommandations de la HAS.
                            </div>
                            <div>
                                <Link
                                    onClick={() => removeError()}
                                    to="/inscription"
                                    className="button button__secondaryButton mx-0"
                                >
                                    S'inscrire
                                </Link>
                            </div>
                        </div>
                        <div className="mt-2 txtUnderline">
                            <Link onClick={() => removeError()} to="/cgu">
                                {" "}
                                CGU
                            </Link>
                        </div>
                    </div>
                </main>
            </div>
        </PractitionerLayoutAnonymous>
    ) : (
        <Redirect
                to={{
                    pathname: redirectUrlAfterAuthPath('/mes-patients'),
                    state: { from: location }
                }}
            />
    );
};

LoginScreen.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = ({ auth }) => ({ auth });

export default withRouter(connect(mapStateToProps,)(LoginScreen));
