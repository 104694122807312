import React from "react";
import PropTypes from 'prop-types';
import { useHistory, useParams, withRouter } from "react-router-dom";

import { connect } from "react-redux";
import Questions from "../../../../actions/questions";

import { Animated } from "react-animated-css";
import { DefaultLoader, Button, PageTitle, useDidUpdate, Form, DynamicQuestions } from "@rdcs/dap-front-library";

import PatientNavLayout from "../../../../components/Patients/PatientNavLayout";

import { tryCreateQuestionnairePatient, tryEditQuestionnairePatient, tryQuestionnairePatient } from "../../../../actions/questionnaire";

import useState from './useState';
import ConcentUpload from "../../../../components/BreakScoreQuestionnaire/ConcentUpload";
import API from "../../../../utils/API";

const { tryFetchStudyAnswers, tryAddStudyAnswer, tryEditStudyAnswer } = Questions;

const PatientsNavStudyQuestionsScreen = ({
    studyQuestions,
    studyAnswers,
    studyQuestionsModificationLoading,
    tryCreateQuestionnairePatient,
    tryQuestionnairePatient
}) => {
    const history = useHistory();
    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);
    const patientIri = React.useMemo(() => `/patients/${patientId}`, [patientId]);


    const [{
        consent,
        loading,
        questionsLoaded,
        answersLoaded,
    }, {
        setConsent,
        questionsEndLoad,
        answersEndLoad,
        initAnswer,
    }] = useState();


    const onUploadConcent = (consent) => API.uploadFile(consent)
        .then(item => setConsent(item['@id']))
        .catch(() => setConsent());

    const onSubmit = (form) => {
        const modifiedObject = studyQuestions.map((question, index) => ({
            question: question.label,
            values: [form[`response${index}`]]
        }));

        const data = {
            type : 'study',
            answers : modifiedObject,
            patient : patientIri
        }

        tryCreateQuestionnairePatient(data)
        
    }
    React.useEffect(() => {
        
        const data = {
            patientId: patientId,
            type: "study"
        }
        tryQuestionnairePatient(data).then(answersEndLoad);
        
    }, []);

    React.useEffect(() => {
        if (questionsLoaded && answersLoaded) {
            initAnswer(studyQuestions, studyAnswers);
        }
    }, [questionsLoaded && answersLoaded]);

    useDidUpdate(() => {
        if (!loading && !studyQuestions.filter(studyQuestion => studyQuestion.active).length) {
            history.push(`/mes-patients/${patientId}/dashboard`);
        }
    }, [loading]);

    return (
        <PageTitle title="Timkl - Questionnaire d'étude">
            <PatientNavLayout patientId={patientId} onLoad={questionsEndLoad}>
                <ConcentUpload
                    className="breakScoreQuestionnaire__fieldset mb-4"
                    onUpload={onUploadConcent}
                    //document={concentStudyQuestionnaire}
                    //filename="concent_study_questionnaire"
                />
                <div className="informationsList breakScoreQuestionnaire__fieldset">
                    <div className="page-single-patient">
                        {loading ? (
                            <DefaultLoader color="#CCC" />
                        ) : (
                            <Animated animationIn="fadeIn">
                                <Form onSubmit={onSubmit}>
                                    <DynamicQuestions
                                        questions={studyQuestions}
                                        answers={studyAnswers}
                                    />
                                    <Button
                                        className="button__primaryButton ml-0"
                                        isLoading={studyQuestionsModificationLoading}
                                        disabled={consent === null}
                                    >
                                        Enregistrer
                                    </Button>
                                </Form>
                            </Animated>
                        )}
                    </div>
                </div>
            </PatientNavLayout>
        </PageTitle>
    );
}

PatientsNavStudyQuestionsScreen.propTypes = {
    studyQuestions: PropTypes.arrayOf(PropTypes.object),
    studyAnswers: PropTypes.arrayOf(PropTypes.object),
    studyQuestionsModificationLoading: PropTypes.bool.isRequired,
    tryFetchStudyAnswers: PropTypes.func.isRequired,
    tryAddStudyAnswer: PropTypes.func.isRequired,
    tryEditStudyAnswer: PropTypes.func.isRequired
};

const mapStateToProps = ({
    questions: {
        studyQuestions,
        studyAnswers,
        createLoading,
        editLoading
    }
}) => ({
    studyQuestions,
    studyAnswers,
    studyQuestionsModificationLoading: createLoading || editLoading,
});

const mapDispatchToProps = {
    tryFetchStudyAnswers,
    tryAddStudyAnswer,
    tryEditStudyAnswer,
    tryCreateQuestionnairePatient,
    tryEditQuestionnairePatient,
    tryQuestionnairePatient
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientsNavStudyQuestionsScreen));
