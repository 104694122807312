import React from "react";
import { withRouter, Link, useParams, useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import {
    Button,
    Icon,
    InputDocument,
    PractitionerLayoutAnonymous,
    PractitionerLayoutAsideAnonymous,
    Grecaptcha,
    PageTitle
} from "@rdcs/dap-front-library";

import API from "../../../utils/API";
import { isLocalAPI } from "../../../utils/utils";

import useState from './useState';

const SubmitJustificatoryScreen = () => {
    const { uid } = useParams();
    const history = useHistory();
    const gRecaptchaRef = React.useRef();
    const [{
        justificatory,
        justificatoryError,
        submitLoading
    }, {
        setJustificatory,
        submitJustificatory,
        submitJustificatoryError
    }] = useState();

    const uploadDocumentAction = (file, token) => API.uploadFileRecaptcha(file, token, setJustificatory);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (justificatory) {
            submitJustificatory();
            API.request({
                path: isLocalAPI
                    ? "/practitioner_submit_justificatory"
                    : "/dap-hcp-patient/api/v1/customers/practitioners/submit-justificatory",
                method: "POST",
                data: {
                    uid: uid,
                    justificatoryId: justificatory.substring('/files/'.length)
                }
            })
            .then(() => {
                toast.success("Votre justificatif a été soumis");
                history.push("/");
            })
            .catch(response => submitJustificatoryError(response.body.error));
        } else {
            submitJustificatoryError('Le justificatif est requis');
        }
    }

    return (
        <PageTitle title='Timkl - Déposer un justificatif'>
            <PractitionerLayoutAnonymous>
                <div className="grid credentials__container">
                    <Grecaptcha ref={gRecaptchaRef} />
                    <PractitionerLayoutAsideAnonymous />
                    <main className="col-sm-50 credentialsMain__container">
                        <form className="credentialsMain" onSubmit={handleSubmit}>
                            <Link to="/connexion" className="credentialsMain__back">
                                <Icon name="arrow-left" />
                            </Link>
                            <h1 className="credentials__title">Soumettre un justificatif</h1>
                            <InputDocument
                                label="Charger un justificatif"
                                documentName="Mon justificatif"
                                legend="Justificatif accepté : carte professionnelle, certificat de scolarité, en-tête d'ordonnance, en-tête de fiche de paie."
                                uploadAction={uploadDocumentAction}
                                gRecaptchaRef={gRecaptchaRef}
                            />
                            {justificatoryError && (
                                <div className="mt-2 txtError">{justificatoryError}</div>
                            )}
                            <div className="buttons__callToAction">
                                <Button
                                    type="submit"
                                    className="button__primaryButton"
                                    isLoading={submitLoading}
                                >
                                    Valider
                                </Button>
                            </div>
                        </form>
                    </main>
                </div>
            </PractitionerLayoutAnonymous>
        </PageTitle>
    );
}

SubmitJustificatoryScreen.propTypes = {};

export default withRouter(SubmitJustificatoryScreen);
