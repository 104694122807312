import React from "react";
import PropTypes from 'prop-types';
import { withRouter, useParams } from "react-router-dom";

import { connect } from "react-redux";
import { tryCreateNote, tryFetchNotes } from "../../../actions/notes";

import { Icon, Modal, DefaultLoader, PageTitle, getViolations } from "@rdcs/dap-front-library";

import NoteItem from "../../../components/Notes/NoteItem";
import PatientNoteForm from "../../../components/Patients/PatientNoteForm";
import PatientNavLayout from "../../../components/Patients/PatientNavLayout";

const PatientsNavNotesScreen = ({
    notes,
    tryCreateNote,
    tryFetchNotes,
}) => {
    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);
    const modalNotesCreateRef = React.useRef();

    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        tryFetchNotes(patientId).finally(() => setLoading(false));
    }, []);

    const patientNotes = React.useMemo(() => (notes.list
        .filter(note => note.active === true)
        .sort((a, b) => {
            if (a.updatedAt && b.updatedAt) {
                return new Date(b.updatedAt) - new Date(a.updatedAt);
            } else {
                return new Date(b.createdAt) - new Date(a.createdAt);
            }
        })
    ), [notes.list]);

    return (
        <PageTitle title='Timkl - Mes Patients'>
            <PatientNavLayout patientId={patientId}>
                {loading ? (
                    <DefaultLoader color="#CCC" />
                ) : (
                    <div>
                        <div className="dashboardContent__element">
                            <div>
                                {
                                    patientNotes.length !== 0 && (
                                        <div className="reportsList grid grid__padding-10">
                                            {patientNotes.map(note => (
                                                <div className='col-md-50 col-sm-100' key={note.id}>
                                                    <NoteItem patientId={patientId} note={note} />
                                                </div>
                                            ))}
                                        </div>
                                    )
                                }
                                <div>
                                    <Modal
                                        buttonContent={
                                            <div className="reportsListItem__add">
                                                <Icon name="add" size="30px" />
                                                <span className="reportsListItem__add-text">
                                                    Ajouter une note
                                                </span>
                                            </div>
                                        }
                                        isSmall
                                        ref={modalNotesCreateRef}
                                    >
                                        <div className="modal__title modal__title--small">Ajouter une note</div>
                                        <PatientNoteForm
                                            patientId={patientId}
                                            onSubmit={form => 
                                                tryCreateNote(form).then(() => {
                                                    modalNotesCreateRef.current.toggleModal();
                                                })
                                            }
                                            isLoading={notes.createLoading}
                                            errors={getViolations(notes.createError)}
                                        />
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </PatientNavLayout>
        </PageTitle>
    );
}

PatientsNavNotesScreen.propTypes = {
    notes: PropTypes.object.isRequired,
    tryCreateNote: PropTypes.func.isRequired,
    tryFetchNotes: PropTypes.func.isRequired,
};

const mapStateToProps = ({ notes }) => ({ notes });

const mapDispatchToProps = {
    tryCreateNote,
    tryFetchNotes,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientsNavNotesScreen));
