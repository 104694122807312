import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import { Modal, InputDateTime, InputText, Button, Icon } from "@rdcs/dap-front-library";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { tryCreatePatientAppointment } from "../../../actions/patients";

const AddVisit = ({ tryCreatePatientAppointment }) => {
    const modalRefAddVisit = React.useRef(null);

    const [topic, setTopic] = useState("");
    const [date, setDate] = useState(null);

    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);

    const [isLoading, setIsLoading] = React.useState(false);

    const [errorDate, setErrorDate] = useState(null);

    const handleClickAddVisit = (e) => {
        e.preventDefault();
        const aDate = moment(date);
        const actualDate = moment(new Date());

        if (aDate.isAfter(actualDate)) {
            setErrorDate("La date doit être antérieure à maintenant !");
            return;
        }

        setIsLoading(true);
        setErrorDate(null);
        tryCreatePatientAppointment({
            topic: topic,
            date: date,
            patientIri: `/patients/${patientId}`,
            doneAt: date,
        })
            .then(() => {
                modalRefAddVisit.current.toggleModal();
            })
            .catch((error) => error.message)
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleChangeTopic = (value) => {
        setTopic(value);
    };

    const handleChangeDate = (value) => {
        setDate(value);
    };

    return (
        <Modal
            buttonContent={
                <div className="d-flex align-items-center timkl__color--blue">
                    <Icon name="add-circle" />
                    <p className="ml-1 timkl__color--blue" style={{ marginTop: "-5px" }}>
                        Ajouter une visite à la liste
                    </p>
                </div>
            }
            ref={modalRefAddVisit}
        >
            <>
                <p className="timkl__color--blue-light"> Ajouter consultation</p>
                <InputText label="Nom de la consultation" name="topic" value={topic} onChange={handleChangeTopic}></InputText>

                <InputDateTime
                    placeholder="Date de la visite"
                    displayFormat="dd-MM-yyyy à H:mm"
                    showTimeSelect={true}
                    label="Date de la consultation"
                    onChange={handleChangeDate}
                    minDate={new Date()}
                    required
                    error={errorDate}
                />
                <div className="d-flex justify-center">
                    <Button className="mt-2 button__primaryButton" onClick={(e) => handleClickAddVisit(e)} isLoading={isLoading}>
                        Valider
                    </Button>
                </div>
            </>
        </Modal>
    );
};

AddVisit.propTypes = {
    tryCreatePatientAppointment: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = { tryCreatePatientAppointment };

export default connect(mapStateToProps, mapDispatchToProps)(AddVisit);
