import React from "react";
import PropTypes from 'prop-types';
import { Link, useHistory, useParams, withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { tryFetchProfileConsents, tryFinalizeProfile } from "../../../actions/profile";

import {
    PractitionerLayoutAnonymous,
    PractitionerLayoutAsideAnonymous,
    Icon,
    DefaultLoader,
    getViolations,
    PageTitle,
} from "@rdcs/dap-front-library";

import { isLocalAPI } from "../../../utils/utils";
import API from "../../../utils/API";
import FinalizeSignInForm from "../../../components/SignIn/FinalizeSignInForm";
import useState from "./useState";

const FinalizeSignInScreen = ({ profile, tryFinalizeProfile, tryFetchProfileConsents }) => {
    const { uid } = useParams();
    const history = useHistory();

    const [{
        practitioner,
        practitionerLoading,
        practitionerError
    }, {
        setPractitioner,
        setPractitionerError
    }] = useState();

    React.useEffect(() => {
        Promise.all([
            tryFetchProfileConsents(),
            API.request({
                path: isLocalAPI
                    ? "/finalize_practitioner/{uid}"
                    : "/dap-hcp-patient/api/v1/customers/practitioners/finalize/{uid}",
                uriParams: {
                    uid
                }
            })
        ])
        .then(([, response]) => setPractitioner(response))
        .catch(setPractitionerError);
    }, []);

    const finalizeProfile = form =>
        tryFinalizeProfile(uid, form)
            .then(() => history.push("/"))
            .catch(() => undefined)
    ;

    return (
        <PageTitle title="Timkl - Finalisation de votre inscription">
            <PractitionerLayoutAnonymous>
                <div className="grid credentials__container">
                    <PractitionerLayoutAsideAnonymous />
                    <main className="col-sm-50 credentialsMain__container">
                        <div className="credentialsMain">
                            <Link to="/connexion" className="credentialsMain__back">
                                <Icon name="arrow-left" />
                            </Link>
                            <h1 className="credentials__title">Finalisation de votre inscription</h1>
                            {practitionerLoading ? (
                                <DefaultLoader />
                            ) : !practitionerLoading && practitionerError ? (
                                <div className="bgError radius shadow mt-4 mb-2 pt-2 pl-3 pb-2 pr-3">
                                    Demande invalide
                                </div>
                            ) : (
                                <FinalizeSignInForm
                                    practitioner={practitioner}
                                    consents={profile.profileConsents}
                                    submit={finalizeProfile}
                                    loading={profile.profileFinalizeLoading}
                                    violations={getViolations(profile.profileFinalizeError)}
                                />
                            )}
                        </div>
                    </main>
                </div>
            </PractitionerLayoutAnonymous>
        </PageTitle>
    );
};

FinalizeSignInScreen.propTypes = {
    profile: PropTypes.object.isRequired,
    tryFinalizeProfile: PropTypes.func.isRequired,
    tryFetchProfileConsents: PropTypes.func.isRequired,
};

const mapStateToProps = ({ profile }) => ({ profile });

const mapDispatchToProps = {
    tryFinalizeProfile,
    tryFetchProfileConsents
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FinalizeSignInScreen));