import React from "react";
import PropTypes from 'prop-types';
import { Redirect, useLocation, withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { tryLogout } from "../../actions/auth";

const LogoutScreen = ({ tryLogout }) => {
    const location = useLocation();

    React.useEffect(() => {
        tryLogout();
    }, []);

    return (
        <Redirect
            to={{
                pathname: "/connexion",
                state: {
                    from: location,
                    userDisabled: location.state && location.state.userDisabled
                }
            }}
        />
    );
}

LogoutScreen.propTypes = {
    tryLogout: PropTypes.func.isRequired,
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    tryLogout
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogoutScreen));
