import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { tryUpdateUserInfos } from "../../actions/auth";
import { tryFetchProfile, tryEditProfile } from "../../actions/profile";
import { tryFetchCoordinators } from "../../actions/coordinators";

import { Animated } from "react-animated-css";
import { toast } from "react-toastify";
import {
    Button,
    DefaultLoader,
    getPrivateFileUrl,
    downloadFileLink,
    PageTitle,
} from "@rdcs/dap-front-library";
import * as queryString from "query-string";

import AppLayout from "../../components/Layout/AppLayout";
import ProfileForm from "../../components/Profile/ProfileForm";
import ProfileJustificatoryForm from "../../components/Profile/ProfileJustificatoryForm";
import { isLocalAPI } from "../../utils/utils";
import API from "../../utils/API";

const ProfileScreen = ({
    auth,
    profile,
    coordinators,
    tryFetchProfile,
    tryEditProfile,
    tryUpdateUserInfos,
    tryFetchCoordinators,
}) => {
    const history = useHistory();
    const urlParams = React.useMemo(
        () => queryString.parse(history.location.search),
        [history.location.search]
    );

    const [personalDataLoading, setPersonalDataLoading] = React.useState(false);

    const getPersonalData = (e) => {
        e.preventDefault();

        if (!personalDataLoading) {
            setPersonalDataLoading(true);
            API.request({
                path: isLocalAPI
                    ? "/export-personal-data"
                    : "/dap-hcp-patient/api/v1/customers/export-personal-data",
                token: auth.access_token,
            })
                .then(() => {
                    toast.success(
                        "Un lien de téléchargement de vos données personnelles vous a été envoyé par email",
                        {
                            autoClose: 5000,
                        }
                    );
                })
                .catch(() => {
                    toast.error(
                        "Vos données personnelles n'ont pas pu être exportées"
                    );
                })
                .finally(() => setPersonalDataLoading(false));
        }
    };

    React.useEffect(() => {
        tryFetchProfile();
        tryFetchCoordinators(auth);
        if (urlParams.downloadPersonalData) {
            toast.info(
                "Vos données personnelles sont en cours de téléchargement",
                { autoClose: 5000 }
            );
            getPrivateFileUrl(urlParams.downloadPersonalData)
                .then((file) => {
                    downloadFileLink(file.url, "Personal_data.zip");
                    toast.success(
                        "Vos données personnelles ont bien été téléchargées.",
                        { autoClose: 5000 }
                    );
                })
                .catch(() =>
                    toast.error("Une erreur s'est produite", {
                        autoClose: 5000,
                    })
                );
        }
    }, []);

    return (
        <PageTitle title="Timkl - Profil">
            <AppLayout navActive={null}>
                <div className="grid">
                    <main className="col-sm-offset-10 col-sm-80">
                        {!(profile && coordinators) ? (
                            <DefaultLoader />
                        ) : (
                            <Animated animationIn="fadeIn">
                                <h1 className="title-1">Mon profil</h1>

                                <ProfileForm
                                    profile={profile}
                                    coordinators={coordinators}
                                    submit={(profile) =>
                                        tryEditProfile(profile)
                                            .then((updatedProfile) =>
                                                tryUpdateUserInfos(
                                                    updatedProfile.avatar,
                                                    updatedProfile.firstName,
                                                    updatedProfile.lastName
                                                )
                                            )
                                            .catch(console.error)
                                    }
                                />
                                <div className="card mt-4">
                                    <h2 className="title-2 mb-2">
                                        Export de mes données personnelles
                                    </h2>
                                    <Button
                                        className="button button__secondaryButton"
                                        onClick={getPersonalData}
                                        isLoading={personalDataLoading}
                                    >
                                        Télécharger mes données personnelles
                                    </Button>
                                </div>

                                <div className="card mt-4">
                                    <ProfileJustificatoryForm />
                                </div>
                            </Animated>
                        )}
                    </main>
                </div>
            </AppLayout>
        </PageTitle>
    );
};

ProfileScreen.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    coordinators: PropTypes.arrayOf(PropTypes.object).isRequired,
    tryFetchProfile: PropTypes.func.isRequired,
    tryEditProfile: PropTypes.func.isRequired,
    tryUpdateUserInfos: PropTypes.func.isRequired,
    tryFetchCoordinators: PropTypes.func.isRequired,
};

const mapStateToProps = ({
    auth,
    profile: { profile },
    coordinators: { list },
}) => ({
    auth,
    profile,
    coordinators: list,
});

const mapDispatchToProps = {
    tryFetchProfile,
    tryEditProfile,
    tryUpdateUserInfos,
    tryFetchCoordinators,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreen);
