import React from "react";
import PropTypes from 'prop-types';
import { useParams, withRouter } from "react-router-dom";

import { connect } from "react-redux";
import {
    trySinglePatient,
    tryFetchPatientTasks,
    tryCreatePatientTask,
    tryEditPatientTask,
    tryFetchTaskLabels
} from "../../../../actions/patients";

import { DefaultLoader, Icon, InputCheckbox, Modal, PageTitle } from "@rdcs/dap-front-library";

import PatientNavLayout from "../../../../components/Patients/PatientNavLayout";
import PatientTaskForm from "../../../../components/Patients/PatientTaskForm";

import Task from './Task';

const PatientsNavTasksScreen = ({
    patientPractitioners,
    patientTasks,
    taskLabels,
    createTaskLoading,
    trySinglePatient,
    tryFetchPatientTasks,
    tryCreatePatientTask,
    tryEditPatientTask,
    tryFetchTaskLabels
}) => {
    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);

    const createTaskRef = React.useRef();

    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        Promise.all([
            tryFetchPatientTasks(patientId),
            trySinglePatient(patientId),
            tryFetchTaskLabels()
        ]).finally(() => setLoading(false));
    }, []);

    return (
        <PageTitle title='Timkl - Mes Patients'>
            <PatientNavLayout patientId={patientId}>
                {loading ? (
                    <DefaultLoader />
                ) : (
                    <>
                        <div className="dashboardContent__element">
                            <div className="dashboardContent__title">
                                <div className="dashboardContent__titleItem">
                                    <Icon name="actions" size="30px" />
                                    <span className="dashboardContent__titleText">Liste des actions</span>
                                </div>
                            </div>
                            <div className="tasksList">
                                {patientTasks
                                    .sort((a, b) => {
                                        if (a.done && !b.done) return 1;
                                        if (!a.done && b.done) return -1;
                                        return (new Date(a.dueAt)).getTime() - (new Date(b.dueAt)).getTime();
                                    })
                                    .map(task => (
                                        <InputCheckbox
                                            key={task.id}
                                            label={<Task task={task} />}
                                            defaultChecked={task.done}
                                            onChange={done =>
                                                tryEditPatientTask({
                                                    id: task.id,
                                                    done: done
                                                })
                                            }
                                        />
                                    ))
                                }
                            </div>
                            <div>
                                <Modal
                                    buttonContent={
                                        <div className="reportsListItem__add">
                                            <Icon name="add" size="30px" />
                                            <span className="reportsListItem__add-text">Ajouter une action</span>
                                        </div>
                                    }
                                    ref={createTaskRef}
                                    isSmall
                                >
                                    <div className="modal__title modal__title--small">Ajouter une action</div>
                                    <PatientTaskForm
                                        patientId={patientId}
                                        patientPractitioners={patientPractitioners}
                                        taskLabels={taskLabels}
                                        isLoading={createTaskLoading}
                                        onSubmit={form => {
                                            tryCreatePatientTask(form).then(() => {
                                                createTaskRef.current.toggleModal();
                                            });
                                        }}
                                    />
                                </Modal>
                            </div>
                        </div>
                    </>
                )}
            </PatientNavLayout>
        </PageTitle>
    );
}

PatientsNavTasksScreen.propTypes = {
    patientPractitioners: PropTypes.arrayOf(PropTypes.object).isRequired,
    patientTasks: PropTypes.arrayOf(PropTypes.object).isRequired,
    taskLabels: PropTypes.arrayOf(PropTypes.object).isRequired,
    createTaskLoading: PropTypes.bool.isRequired,
    trySinglePatient: PropTypes.func.isRequired,
    tryFetchPatientTasks: PropTypes.func.isRequired,
    tryCreatePatientTask: PropTypes.func.isRequired,
    tryEditPatientTask: PropTypes.func.isRequired,
    tryFetchTaskLabels: PropTypes.func.isRequired,
};

const mapStateToProps = ({
    patients: {
        singlePatient,
        patientTasks,
        taskLabels,
        createTaskLoading
    }
}) => ({
    patientPractitioners: singlePatient?.accessedBy || [],
    patientTasks,
    taskLabels,
    createTaskLoading
});

const mapDispatchToProps = {
    trySinglePatient,
    tryFetchPatientTasks,
    tryCreatePatientTask,
    tryEditPatientTask,
    tryFetchTaskLabels
};

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(PatientsNavTasksScreen));
