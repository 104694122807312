import React from "react";
import PropTypes from 'prop-types';
import { withRouter, useParams } from "react-router-dom";

import { connect } from "react-redux";
import { trySinglePatient, tryEditPatient } from "../../../actions/patients";

import { Icon, DefaultLoader, PageTitle } from "@rdcs/dap-front-library";

import PatientsProSanteItem from "../../../components/Patients/PatientsProSanteItem";
import PatientNavLayout from "../../../components/Patients/PatientNavLayout";

import defaultUserImage from "@rdcs/dap-front-library/static/images/default-user.png";

const PatientsNavProSante = ({
    singlePatient,
    trySinglePatient,
    tryEditPatient
}) => {
    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);

    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        trySinglePatient(patientId).finally(() => setLoading(false));
    }, []);

    const putPointOfContact = React.useCallback(HCPID => {
        tryEditPatient(patientId, { pointOfContact: { id: HCPID } });
    }, [patientId]);

    const arrayOfProfessions = React.useMemo(() => {
        if (!singlePatient?.accessedBy.length) {
            return  <p>Aucun médecin n'est attitré à ce patient</p>;
        }

        const lArrayOfProfessions = [];
        const result = [];
        singlePatient.accessedBy.forEach(item => {
            if (item.user.profession && lArrayOfProfessions.indexOf(item.user.profession) === -1) {
                lArrayOfProfessions.push(item.user.profession);
                result.push({
                    id: item.user.id,
                    visuel: defaultUserImage,
                    title: item.user.profession
                });
            }
        });
        return result
                .sort((a, b) => (a.title < b.title ? -1 : a.title > b.title ? 1 : 0))
                .map((item, index) => (
                    <div key={item.id} className={`proSanteList ${index === 0 ? 'mt-0' : ''}`}>
                        <div
                            className="proSanteList__photo"
                            style={{ backgroundImage: "url(" + item.visuel + ")" }}
                        >
                            <img src={item.visuel} alt={"Icône de " + item.title} />
                        </div>{" "}
                        <span className="text-2">{item.title}</span>
                        {singlePatient.accessedBy
                            .filter(aR => aR.user.profession === item.title)
                            .map((ar, index) => (
                                <PatientsProSanteItem
                                    key={index}
                                    accessRight={ar}
                                    pointOfContact={singlePatient.pointOfContact}
                                    onSubmit={HCPID => putPointOfContact(HCPID)}
                                />
                            ))}
                    </div>
                ));
    }, [singlePatient?.accessedBy, putPointOfContact]);

    return (
        <PageTitle title='Timkl - Mes Patients'>
            <PatientNavLayout patientId={patientId}>
                {loading ? <DefaultLoader /> : arrayOfProfessions}
            </PatientNavLayout>
        </PageTitle>
    );
};

PatientsNavProSante.propTypes = {
    singlePatient: PropTypes.object,
    trySinglePatient: PropTypes.func.isRequired,
    tryEditPatient: PropTypes.func.isRequired,
};

const mapStateToProps = ({
    patients: {
        singlePatient
    },
}) => ({ singlePatient });

const mapDispatchToProps = {
    trySinglePatient,
    tryEditPatient
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientsNavProSante));
