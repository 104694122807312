import React from "react";
import PropTypes from "prop-types";
import { withRouter, useParams } from "react-router-dom";

import { connect } from "react-redux";
import { tryFetchMeasures, tryCreateMeasure, tryFetchMeasureTypes } from "../../../../actions/measures";
import { tryFetchLastDiagnosis } from "../../../../actions/diagnosis";

import { DefaultLoader, Icon, InputDateTime, InputSymbol, Select, PageTitle, getViolations, formatHBA1C, ChartLine } from "@rdcs/dap-front-library";
import Moment from "moment";

import PatientNavLayout from "../../../../components/Patients/PatientNavLayout";

import useState from "./useState";

const PatientsNavDataScreen = ({
    measuresCreateError,
    listMeasureTypes,
    diagnosis,
    tryFetchMeasures,
    tryCreateMeasure,
    tryFetchMeasureTypes,
    tryFetchLastDiagnosis,
}) => {
    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);
    const [
        { loading, medicalPatient, measures, mainMeasures, othersMeasures, chartData },
        {
            init,
            endLoad,
            loadMedicalPatient,
            addPatientMeasure,
            addMeasureTypeUnit,
            addMeasureTypeDate,
            addMeasureTypeValue,
            mainMeasuresShowAddItem,
            addMainMeasureTypeDate,
            addMainMeasureTypeValue,
            mainMeasuresShowMoreItem,
            showFilterItem,
            showAddItem,
            setFilterMeasure,
            setFilterStartDate,
            setFilterEndDate,
            otherMeasuresShowMoreItem,
        },
    ] = useState(patientId);

    React.useEffect(() => {
        Promise.all([tryFetchMeasures(patientId), tryFetchMeasureTypes(), tryFetchLastDiagnosis(patientId)])
            .then(([measuresList, measureTypesList]) => init(measuresList, measureTypesList))
            .catch(endLoad);
    }, []);

    const hba1cObjectif = React.useMemo(
        () =>
            diagnosis.patientLastDiagnosis
                ? formatHBA1C(diagnosis.patientLastDiagnosis.goal) // Last diagnosis HbA1c
                : formatHBA1C(chartData.values[chartData.values.length - 1]), // Last data HbA1c
        [diagnosis.patientLastDiagnosis]
    );

    const listMeasureTypesOptions = React.useMemo(
        () => [
            {
                label: "--Choisir--",
                value: "",
            },
            ...listMeasureTypes.map((measure) => ({
                label: measure.name,
                value: measure.id,
            })),
        ],
        [listMeasureTypes]
    );

    const [othersMeasuresFilterOptions, othersMeasuresOptions] = React.useMemo(
        () => [
            [
                { label: "Toutes les données", value: "" },
                ...othersMeasures.measureTypes.map((measure) => ({
                    label: measure.name,
                    value: measure["@id"],
                })),
            ],
            [
                { label: "--Choisir--", value: "" },
                ...othersMeasures.measureTypes.map((measure) => ({
                    label: measure.name,
                    value: measure.id,
                })),
            ],
        ],
        [othersMeasures.measureTypes]
    );

    return (
        <PageTitle title="Timkl - Mes Patients">
            <PatientNavLayout patientId={patientId} onLoad={loadMedicalPatient}>
                {medicalPatient ? (
                    loading ? (
                        <DefaultLoader />
                    ) : measures.length === 0 ? (
                        <div>
                            <div className="dataPatientlist form__group">
                                <div className="dataPatientlist__header">
                                    <span className="dataPatientlist__headerName">Ajouter une donnée</span>
                                </div>
                                <form
                                    className="dataPatientItem dataPatientItem--action"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        tryCreateMeasure(othersMeasures.addMeasureData).then(addPatientMeasure).catch(console.error);
                                    }}
                                >
                                    <div className="dataPatientItem__form">
                                        <div className="dataPatientItem__inputGroup inlineForm">
                                            <Select
                                                label="Choisir"
                                                options={listMeasureTypesOptions}
                                                onChange={(val) => {
                                                    const measureTypeId = parseInt(val);

                                                    addMeasureTypeUnit(listMeasureTypes.find((measure) => measure.id === measureTypeId));
                                                }}
                                                required
                                                requiredNotInLabel
                                            />
                                        </div>
                                        <div className="dataPatientItem__inputGroup inlineForm">
                                            <div className="dataPatientItem__nameForm">
                                                <InputDateTime
                                                    label="Date"
                                                    onChange={addMeasureTypeDate}
                                                    maxDate={new Date()}
                                                    error={measuresCreateError.date}
                                                    required
                                                    requiredNotInLabel
                                                />
                                            </div>
                                        </div>
                                        <div className="dataPatientItem__inputGroup inlineForm">
                                            <div className="dataPatientItem__nameForm">
                                                <InputSymbol
                                                    label="Valeur"
                                                    symbol={othersMeasures.addMeasureData.unit}
                                                    onChange={addMeasureTypeValue}
                                                    error={measuresCreateError.value}
                                                    required
                                                    requiredNotInLabel
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dataPatientItem__right">
                                        <button type="submit">
                                            <Icon className="dataPatientItem__submitForm" name="check-circle" size="32px" />
                                            <span className="dataPatientItem__submitForm">Valider l'ajout</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {/* Chart with HbA1c datas */}
                            <div className="contentHolder__title grid">
                                <div className="col-md-20 col-sm-100 chartLabelContainer">
                                    <p className="chartLabel">Objectif HbA1c actuel&nbsp;:</p>
                                    <p className="chartLabelNumber">{(hba1cObjectif || "0").replace(".", ",")}%</p>
                                </div>
                                <div className="col-md-80 col-sm-100">
                                    <ChartLine hba1cObjectif={parseFloat(hba1cObjectif)} chartDates={chartData.dates} chartValues={chartData.values} />
                                </div>
                            </div>

                            {/* Main categories patient's datas (defined in defineDefaultState() function in mainCategoriesName constant) */}
                            {mainMeasures.measureTypes.map((item, index) => (
                                <div key={index} className="dataPatientlist">
                                    <div>
                                        <div className="dataPatientlist__header">
                                            <span className="dataPatientlist__headerName">{item.name}</span>
                                            <span className="dataPatientlist__headerAction">
                                                <span
                                                    className="dataPatientlist__headerAction-item"
                                                    onClick={() => mainMeasuresShowAddItem(item["@id"], index)}
                                                >
                                                    <Icon className="dataPatientlist__headerAction-content" name="add" size="30px" />
                                                    <span className="dataPatientlist__headerAction-content">Ajouter une mesure {item.name}</span>
                                                </span>
                                            </span>
                                        </div>
                                        {mainMeasures.showAddItem[index] && (
                                            <form
                                                className="dataPatientItem dataPatientItem--action"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    tryCreateMeasure(mainMeasures.addMeasureData[index]).then(addPatientMeasure).catch(console.error);
                                                }}
                                            >
                                                <div className="dataPatientItem__title">{item.name}</div>
                                                <div className="dataPatientItem__form">
                                                    <div className="dataPatientItem__inputGroup inlineForm">
                                                        <InputDateTime
                                                            label="Date"
                                                            onChange={(date) => addMainMeasureTypeDate(date, index)}
                                                            maxDate={new Date()}
                                                            error={measuresCreateError.date}
                                                            required
                                                            requiredNotInLabel
                                                        />
                                                    </div>
                                                    <div className="dataPatientItem__inputGroup inlineForm">
                                                        <InputSymbol
                                                            label="Valeur"
                                                            symbol={item.unit}
                                                            min={0}
                                                            onChange={(value) => addMainMeasureTypeValue(value, index)}
                                                            error={measuresCreateError.value}
                                                            required
                                                            requiredNotInLabel
                                                        />
                                                    </div>
                                                    <div className="dataPatientItem__right">
                                                        <button type="submit">
                                                            <Icon className="dataPatientItem__submitForm" name="check-circle" size="32px" />
                                                            <span className="dataPatientItem__submitForm">Valider l'ajout</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        )}
                                        {measures
                                            .filter((measure) => measure.type.id === item.id)
                                            .slice(0, mainMeasures.showMoreItem[index])
                                            .map((measure, indexMeasure) => (
                                                <div key={indexMeasure} className="dataPatientItem">
                                                    <div className="dataPatientItem__title">
                                                        {measure.type.name}
                                                        <span className="dataPatientItem__titleSubtitle">
                                                            {Moment(measure.date).format("[Le ] Do MMMM YYYY")}
                                                        </span>
                                                    </div>
                                                    <div className="dataPatientItem__right">
                                                        <div className="dataPatientItem__percent">{measure.value + " " + measure.type.unit}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        <div className="dataPatientItem__footerAction">
                                            {measures.filter((measure) => measure.type.id === item.id).length > mainMeasures.showMoreItem[index] && (
                                                <button className="dataPatientItem__footerAction-button" onClick={() => mainMeasuresShowMoreItem(index)}>
                                                    Mesures précédentes
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {/* Others categories patient's datas */}
                            <div className="dataPatientlist">
                                <div>
                                    <div className="dataPatientlist__header">
                                        <span className="dataPatientlist__headerName">Autres données</span>
                                        <span className="dataPatientlist__headerAction">
                                            <span className="dataPatientlist__headerAction-item" onClick={showFilterItem}>
                                                <Icon className="dataPatientlist__headerAction-content" name="filter" size="16px" />
                                                <span className="dataPatientlist__headerAction-content">Filtrer</span>
                                            </span>
                                            <span className="dataPatientlist__headerAction-item" onClick={showAddItem}>
                                                <Icon className="dataPatientlist__headerAction-content" name="add" size="30px" />
                                                <span className="dataPatientlist__headerAction-content">Ajouter une autre donnée</span>
                                            </span>
                                        </span>
                                    </div>
                                    {othersMeasures.showFilterItem && (
                                        <div className="dataPatientItem dataPatientItem--action">
                                            <div className="dataPatientItem__form">
                                                <div className="dataPatientItem__inputGroup dataPatientItem__inputGroup--selectFilter inlineForm">
                                                    <Select label="Afficher" options={othersMeasuresFilterOptions} onChange={setFilterMeasure} />
                                                </div>
                                                <div className="dataPatientItem__right">
                                                    <div className="dataPatientItem__inputGroup inlineForm">
                                                        <div className="dataPatientItem__filterInput">
                                                            <InputDateTime
                                                                label="de"
                                                                displayFormat="MMMM yyyy"
                                                                defaultValue={new Date(othersMeasures.filter.startDate)}
                                                                onChange={setFilterStartDate}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="dataPatientItem__inputGroup inlineForm">
                                                        <div className="dataPatientItem__filterInput">
                                                            <InputDateTime
                                                                label="à"
                                                                displayFormat="MMMM yyyy"
                                                                defaultValue={new Date(othersMeasures.filter.endDate)}
                                                                onChange={setFilterEndDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {othersMeasures.showAddItem && (
                                        <form
                                            className="dataPatientItem dataPatientItem--action"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                tryCreateMeasure(othersMeasures.addMeasureData).then(addPatientMeasure).catch(console.error);
                                            }}
                                        >
                                            <div className="dataPatientItem__infos">
                                                <div className="dataPatientItem__form">
                                                    <div className="dataPatientItem__inputGroup inlineForm">
                                                        <Select
                                                            label="Choisir"
                                                            options={othersMeasuresOptions}
                                                            error={measuresCreateError.type}
                                                            onChange={(val) => {
                                                                const measureTypeId = parseInt(val);

                                                                addMeasureTypeUnit(listMeasureTypes.find((measure) => measure.id === measureTypeId));
                                                            }}
                                                            required
                                                            requiredNotInLabel
                                                        />
                                                    </div>
                                                    <div className="dataPatientItem__inputGroup inlineForm">
                                                        <InputDateTime
                                                            label="Date"
                                                            onChange={addMeasureTypeDate}
                                                            maxDate={new Date()}
                                                            error={measuresCreateError.date}
                                                            required
                                                            requiredNotInLabel
                                                        />
                                                    </div>
                                                    <div className="dataPatientItem__inputGroup inlineForm">
                                                        <InputSymbol
                                                            label="Valeur"
                                                            symbol={othersMeasures.addMeasureData.unit}
                                                            onChange={addMeasureTypeValue}
                                                            error={measuresCreateError.value}
                                                            required
                                                            requiredNotInLabel
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dataPatientItem__right">
                                                <button type="submit">
                                                    <Icon className="dataPatientItem__submitForm" name="check-circle" size="32px" />
                                                    <span className="dataPatientItem__submitForm">Valider l'ajout</span>
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                    {measures
                                        .filter((measure) => {
                                            // Filter category
                                            if (othersMeasures.filter.category === "") {
                                                // Filter date
                                                if (othersMeasures.filter.startDate === "" || othersMeasures.filter.endDate === "") {
                                                    return othersMeasures.measureTypes.some((category) => measure.type["@id"] === category["@id"]);
                                                } else {
                                                    return othersMeasures.measureTypes.some((category) => {
                                                        if (
                                                            measure.type["@id"] === category["@id"] &&
                                                            measure.date >= othersMeasures.filter.startDate &&
                                                            measure.date <= othersMeasures.filter.endDate
                                                        ) {
                                                            return true;
                                                        }
                                                        return false;
                                                    });
                                                }
                                            } else {
                                                if (measure.type["@id"] === othersMeasures.filter.category) {
                                                    // Filter date
                                                    if (othersMeasures.filter.startDate === "" || othersMeasures.filter.endDate === "") {
                                                        return true;
                                                    } else {
                                                        return measure.date > othersMeasures.filter.startDate && measure.date < othersMeasures.filter.endDate;
                                                    }
                                                }
                                            }
                                            return false;
                                        })
                                        .slice(0, othersMeasures.showMoreItem)
                                        .map((measure, indexMeasure) => (
                                            <div key={indexMeasure} className="dataPatientItem">
                                                <div className="dataPatientItem__infos">
                                                    <p className="dataPatientItem__title">
                                                        {measure.type.name}
                                                        <span className="dataPatientItem__titleSubtitle">
                                                            {Moment(measure.date).format("[Le ] Do MMMM YYYY")}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="dataPatientItem__right">
                                                    <div className="dataPatientItem__percent">{measure.value + " " + measure.type.unit}</div>
                                                </div>
                                            </div>
                                        ))}
                                    <div className="dataPatientItem__footerAction">
                                        {measures.filter((measure) => {
                                            // Filter category
                                            if (othersMeasures.filter.category === "") {
                                                // Filter date
                                                if (othersMeasures.filter.startDate === "" || othersMeasures.filter.endDate === "") {
                                                    return othersMeasures.measureTypes.some((category) => measure.type["@id"] === category["@id"]);
                                                } else {
                                                    return othersMeasures.measureTypes.some((category) => {
                                                        if (
                                                            measure.type["@id"] === category["@id"] &&
                                                            measure.date > othersMeasures.filter.startDate &&
                                                            measure.date < othersMeasures.filter.endDate
                                                        ) {
                                                            return true;
                                                        }
                                                        return false;
                                                    });
                                                }
                                            } else {
                                                if (measure.type["@id"] === othersMeasures.filter.category) {
                                                    // Filter date
                                                    if (othersMeasures.filter.startDate === "" || othersMeasures.filter.endDate === "") {
                                                        return true;
                                                    } else {
                                                        return measure.date > othersMeasures.filter.startDate && measure.date < othersMeasures.filter.endDate;
                                                    }
                                                }
                                            }
                                            return false;
                                        }).length > othersMeasures.showMoreItem && (
                                            <button className="dataPatientItem__footerAction-button" onClick={otherMeasuresShowMoreItem}>
                                                Mesures précédentes
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                ) : null}
            </PatientNavLayout>
        </PageTitle>
    );
};

PatientsNavDataScreen.propTypes = {
    measuresCreateError: PropTypes.object.isRequired,
    listMeasureTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
    diagnosis: PropTypes.object.isRequired,
    tryFetchMeasures: PropTypes.func.isRequired,
    tryCreateMeasure: PropTypes.func.isRequired,
    tryFetchMeasureTypes: PropTypes.func.isRequired,
    tryFetchLastDiagnosis: PropTypes.func.isRequired,
};

const mapStateToProps = ({ measures: { createError, listMeasureTypes }, diagnosis }) => ({
    measuresCreateError: getViolations(createError),
    listMeasureTypes,
    diagnosis,
});

const mapDispatchToProps = {
    tryFetchMeasures,
    tryCreateMeasure,
    tryFetchMeasureTypes,
    tryFetchLastDiagnosis,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientsNavDataScreen));
