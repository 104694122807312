import React from "react";
import { Route, Router, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import history from "./config/history";

import PrivateRoute from "./components/Utils/PrivateRoute";
import { ScrollToTop } from "@rdcs/dap-front-library";

import LoginScreen from "./screens/Login/LoginScreen";
import SignInScreen from "./screens/SignIn/SignInScreen";
import LogoutScreen from "./screens/Login/LogoutScreen";
import PatientsScreen from "./screens/Patients/PatientsScreen";
import PatientsNavInfosScreen from "./screens/Patients/PatientsNav/PatientsNavInfosScreen";
import PatientsNavDataScreen from "./screens/Patients/PatientsNav/PatientsNavDataScreen";
import PatientsNavConsultsScreen from "./screens/Patients/PatientsNav/PatientsNavConsultsScreen";
import PatientsAddScreen from "./screens/Patients/PatientsAddScreen";
import RecommandationsScreen from "./screens/Recommandations/RecommandationsScreen";
import PatientsDiagnosticScreen from "./screens/Patients/PatientsDiagnosticScreen";
import PatientsNavLastDiagnosticScreen from "./screens/Patients/PatientsNav/PatientsNavLastDiagnosticScreen";
import ContactScreen from "./screens/Page/ContactScreen";
import TermsOfUsageScreen from "./screens/Page/TermsOfUsageScreen";
import DataPolicyScreen from "./screens/Page/DataPolicyScreen";
import LegalNoticeScreen from "./screens/Page/LegalNoticeScreen";
import ProfileScreen from "./screens/Profile/ProfileScreen";
import NotFoundExceptionScreen from "./screens/Exceptions/NotFoundException";
import SubmitJustificatoryScreen from "./screens/Email/SubmitJustificatoryScreen";
import DiagnosticValidationScreen from './screens/Email/DiagnosticValidationScreen';
import PatientsNavCoordonneesScreen from "./screens/Patients/PatientsNav/PatientsNavCoordonneesScreen";
import PatientsNavStudyQuestionsScreen from "./screens/Patients/PatientsNav/PatientsNavStudyQuestionsScreen";

import PatientsNavEpicesScreen from "./screens/Patients/PatientsNav/PatientsNavEpicesScreen";
import PatientsNavBeckScreen from "./screens/Patients/PatientsNav/PatientsNavBeckScreen";

import PatientsNavProSanteScreen from "./screens/Patients/PatientsNav/PatientsNavProSante";
import PatientsNavTasksScreen from "./screens/Patients/PatientsNav/PatientsNavTasksScreen";
import PatientsNavDashboardScreen from "./screens/Patients/PatientsNav/PatientsNavDashboardScreen";
import PatientsNavNotesScreen from "./screens/Patients/PatientsNav/PatientsNavNotesScreen";
import PatientsNavExamsScreen from "./screens/Patients/PatientsNav/PatientsNavExamsScreen";
import { allowedRoles, Roles, getRoles } from "./utils/TokenData";
import LoginSuccessScreen from "./screens/Login/LoginSuccessScreen";
import FinalizeSignInScreen from "./screens/SignIn/FinalizeSignInScreen";
import BreakScoreQuestionnaireScreen from "./screens/BreakScoreQuestionnaireScreen";
import PatientsReportScreen from "./screens/Patients/PatientsNav/PatientsReportScreen";

//New Screen - Name and/or structure may change
import PatientsNavBreakScoreScreen from "./screens/Patients/PatientsNav/PatientsNavBreakScoreScreen";

const App = () => (
    <Router history={history}>
        <ScrollToTop>
            <ToastContainer autoClose={3000} />
            <Switch>
                <Redirect exact from="/" to="/connexion" />
                <Route exact path="/login_success" component={LoginSuccessScreen} />
                <Route exact path="/connexion" component={LoginScreen} />
                <Route exact path="/inscription" component={SignInScreen} />
                <Route exact path="/finalisation-inscription/:uid" component={FinalizeSignInScreen} />
                <Route exact path="/cgu" component={TermsOfUsageScreen} />
                <Route exact path="/politique-de-donnees" component={DataPolicyScreen} />
                <Route exact path="/mentions-legales" component={LegalNoticeScreen} />
                <Route exact path="/contact" component={ContactScreen} />
                <Route exact path="/email/submit-justificatory/:uid" component={SubmitJustificatoryScreen} />
                <Route exact path='/email/validation-diagnostic/:token' component={DiagnosticValidationScreen} />
                <PrivateRoute exact path="/mes-patients" component={PatientsScreen} allowedRoles={allowedRoles} />
                <PrivateRoute exact path="/mes-patients/ajouter" component={PatientsAddScreen} allowedRoles={allowedRoles} />
                <PrivateRoute exact path="/mes-patients/ajouter/:id" component={PatientsAddScreen} allowedRoles={allowedRoles} />
                <PrivateRoute exact path="/mes-patients/:id/dashboard" component={PatientsNavDashboardScreen} allowedRoles={allowedRoles} />
                <PrivateRoute exact path="/mes-patients/:id/informations" component={PatientsNavInfosScreen} allowedRoles={allowedRoles} />
                <PrivateRoute exact path="/mes-patients/:id/visites" component={PatientsNavConsultsScreen} allowedRoles={allowedRoles} />
                <PrivateRoute exact path="/mes-patients/:id/donnees" component={PatientsNavDataScreen} allowedRoles={allowedRoles} />
                <PrivateRoute exact path="/mes-patients/:id/medecins" component={PatientsNavProSanteScreen} allowedRoles={allowedRoles} />
                <PrivateRoute exact path="/mes-patients/:id/actions" component={PatientsNavTasksScreen} allowedRoles={allowedRoles} />
                <PrivateRoute exact path="/mes-patients/:id/nouvelle-consultation" component={PatientsDiagnosticScreen} allowedRoles={allowedRoles} />
                <PrivateRoute exact path="/mes-patients/:id/coordonnees" component={PatientsNavCoordonneesScreen} allowedRoles={allowedRoles} />
                <PrivateRoute
                    exact
                    path="/mes-patients/:id/study-questions"
                    component={PatientsNavStudyQuestionsScreen}
                    allowedRoles={getRoles(Roles.ROLE_DOCTOR, Roles.ROLE_NURSE, Roles.ROLE_PHARMACIST)}
                />
                <PrivateRoute
                    exact
                    path="/mes-patients/:id/break-score-screen"
                    component={PatientsNavBreakScoreScreen}
                    allowedRoles={getRoles(Roles.ROLE_DOCTOR, Roles.ROLE_NURSE, Roles.ROLE_PHARMACIST)}
                />
                <PrivateRoute
                    exact
                    path="/mes-patients/:id/questionnaire-beck"
                    component={PatientsNavBeckScreen}
                    allowedRoles={getRoles(Roles.ROLE_DOCTOR, Roles.ROLE_NURSE, Roles.ROLE_PHARMACIST)}
                />
                <PrivateRoute
                    exact
                    path="/mes-patients/:id/questionnaire-epices"
                    component={PatientsNavEpicesScreen}
                    allowedRoles={getRoles(Roles.ROLE_DOCTOR, Roles.ROLE_NURSE, Roles.ROLE_PHARMACIST)}
                />
                <PrivateRoute exact path="/mes-patients/:id/derniere-consultation" component={PatientsNavLastDiagnosticScreen} allowedRoles={allowedRoles} />
                <PrivateRoute exact path="/mes-patients/:id/examens" component={PatientsNavExamsScreen} allowedRoles={allowedRoles} />
                <PrivateRoute exact path="/mes-patients/:id/notes" component={PatientsNavNotesScreen} allowedRoles={allowedRoles} />
                <PrivateRoute exact path="/mes-patients/:id/reports" component={PatientsReportScreen} allowedRoles={allowedRoles} />
                <Route exact path="/mes-patients/:id" render={(props) => <Redirect to={`/mes-patients/${props.match.params.id}/dashboard`} />} />
                <PrivateRoute exact path="/recommandations-de-la-has" component={RecommandationsScreen} allowedRoles={allowedRoles} />
                <PrivateRoute exact path="/mon-profil" component={ProfileScreen} allowedRoles={allowedRoles} />
                <Route exact path="/deconnexion" component={LogoutScreen} />

                <PrivateRoute
                    exact
                    path="/questionnaire-rupture"
                    component={BreakScoreQuestionnaireScreen}
                    allowedRoles={getRoles(Roles.ROLE_PHARMACIST, Roles.ROLE_NURSE, Roles.ROLE_DOCTOR)}
                />

                <Route component={NotFoundExceptionScreen} />
            </Switch>
        </ScrollToTop>
    </Router>
);

export default App;
