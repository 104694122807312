import React from "react";
import PropTypes from 'prop-types';
import { Link, withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { tryMedicalPatients, itemsPerPage } from "../../../actions/patients";
import { userCan } from "../../../reducers/auth";

import moment from "moment";
import { toast } from "react-toastify";
import {
    constant,
    DefaultLoader,
    Icon,
    AntdTooltip,
    AntdPagination,
    ImagePreview,
    PageTitle
} from "@rdcs/dap-front-library";

import AppLayout from "../../../components/Layout/AppLayout";
import { getTreatmentName } from "../../../utils/utils";

import useState from './useState';

import defaultUserImage from "@rdcs/dap-front-library/static/images/default-user.png";

const breadcrumbs = [
    { label: 'Mes patients' }
];

const PatientsScreen = ({
    patientsTotalItems,
    tryMedicalPatients
}) => {
    const [{
        loading,
        patients,
        page
    }, {
        changePage,
        loadPatientsPage,
        errorLoad
    }] = useState();

    React.useEffect(() => {
        if (loading) {
            tryMedicalPatients(page)
            .then((patientsOnPage) => loadPatientsPage(page, patientsOnPage))
            .catch(() => {
                toast.error("Une erreur s'est produite lors de la récupération des patients.");
                errorLoad(page);
            });
        }
    }, [page]);


    return (
        <PageTitle title='Timkl - Mes Patients'>
            <AppLayout navActive={0} breadcrumbs={breadcrumbs}>
                <header className="headerPage">
                    <div className="headerPage__title">
                        <h1 className="title-1">Mes patients</h1>
                    </div>
                    <div className="headerPage__actions">
                        {userCan("ROLE_DOCTOR") && (
                            <Link
                                to="/mes-patients/ajouter"
                                className="headerPage__actions-item button button__primaryButton"
                            >
                                + Ajouter un nouveau patient
                            </Link>
                        )}
                    </div>
                </header>
                <div className="patientsList">
                    {loading ? (
                        <DefaultLoader />
                    ) : patients.length === 0 ? (
                        <div>Aucun patient</div>
                    ) : (
                        patients
                            .map(({ patient }) => (
                                <Link to={`/mes-patients/${patient.id}`} key={patient.id} className="patientsListItem">
                                    <AntdTooltip placement="right" title={patient.statusReason}>
                                        <div
                                            className={`patientsListItem__statusDot statusDot statusDot--${patient.statusColor}`}
                                        />
                                    </AntdTooltip>
                                    <ImagePreview
                                        user={patient}
                                        className="patientsListItem__visuel"
                                        filename={patient.avatar && patient.avatar.filename}
                                        fallbackImageUrl={defaultUserImage}
                                    />
                                    <div className="patientsListItem__infos ml-2">
                                        <div className="patientsListItem__name">
                                            {patient.firstName} {patient.lastName}
                                        </div>
                                        {patient.lateRecommendations > 0 && (
                                            <div className="patientsListItem__recommandation">
                                                {patient.lateRecommendations} recommandation
                                                {patient.lateRecommendations > 1 && "s"} en retard
                                            </div>
                                        )}
                                        <div className="patientsListItem__infos-list">
                                            <span className="patientsListItem__infos-item">
                                                {patient.lastDiagnostic
                                                    ? constant("pathologies", patient.lastDiagnostic.pathology)
                                                    : "Diabète inconnu"}
                                            </span>
                                            <span className="patientsListItem__infos-item patientsListItem__infos-item--bigger">
                                                {patient.lastDiagnostic && patient.lastDiagnostic.treatmentGroup
                                                    ? getTreatmentName(patient.lastDiagnostic.treatmentGroup)
                                                    : "Aucun traitement"}
                                            </span>
                                            <span className="patientsListItem__infos-item">
                                                {constant("sexes", patient.sex)},{" "}
                                                {moment().diff(patient.dateOfBirth, "years")} ans
                                            </span>
                                            <span className="patientsListItem__infos-item">
                                                {patient.tasksTodo !== 0
                                                    ? patient.tasksTodo +
                                                    " tâche" +
                                                    (patient.tasksTodo > 1 ? "s" : "")
                                                    : "Aucune tâche"}
                                            </span>
                                            <span className="patientsListItem__infos-item patientsListItem__infos-item--bigger">
                                                {patient.lastDiagnostic
                                                    ? "Dernière consultation le " +
                                                    moment(patient.lastDiagnostic.createdAt).format("DD/MM/YYYY")
                                                    : "Aucune consultation"}
                                            </span>
                                        </div>
                                    </div>
                                    <Icon name="stats" className="patientsListItem__icon" />
                                </Link>
                            ))
                    )}
                </div>
                <AntdPagination
                    className="mt-3"
                    defaultPageSize={itemsPerPage}
                    current={page}
                    total={patientsTotalItems}
                    onChange={changePage}
                    hideOnSinglePage
                />
            </AppLayout>
        </PageTitle>
    );
}

PatientsScreen.propTypes = {
    patientsTotalItems: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
    tryMedicalPatients: PropTypes.func.isRequired,
};

const mapStateToProps = ({
    patients: {
        medicalPatientsCount: patientsTotalItems
    }
}) => ({ patientsTotalItems });

const mapDispatchToProps = {
    tryMedicalPatients
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientsScreen));