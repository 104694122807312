import React from "react";
import PropTypes from "prop-types";
import { useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Animated } from "react-animated-css";
import { DefaultLoader, PageTitle, Icon, Button } from "@rdcs/dap-front-library";

import Questions from "../../../actions/questions";
import { tryCreateQuestionnairePatient } from "../../../actions/questionnaire";
import { tryQuestionnairePatient } from "../../../actions/questionnaire";
import PatientNavLayout from "../../../components/Patients/PatientNavLayout";
import BeckForm from "../../../components/BeckForm";
import BeckScore from "../../../components/BeckForm/beckScore";

const { tryFetchBeckQuestions } = Questions;

const PatientsNavBeckScreen = ({ beckQuestions, beckAnswers, tryFetchBeckQuestions, tryCreateQuestionnairePatient, tryQuestionnairePatient }) => {
    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);
    const patientIri = React.useMemo(() => `/patients/${patientId}`, [patientId]);

    const [userAnswers, setUserAnswers] = React.useState([]);
    const [answers, setAnswers] = React.useState([]);

    const [questionnaireId, setQuestionnaireId] = React.useState(null);

    const [score, setScore] = React.useState(0);
    const [IsScoreScreen, setIsScoreScreen] = React.useState(false);

    const [isLoadingLocal, setIsLoadingLocal] = React.useState(true);

    const [isFetching, setIsFetching] = React.useState(false);

    React.useEffect(() => {
        setIsLoadingLocal(true);

        const data = {
            patientId: patientId,
            type: "beck",
        };

        Promise.all([tryFetchBeckQuestions(), tryQuestionnairePatient(data)]);
    }, []);

    React.useEffect(() => {
        setIsLoadingLocal(true);
        setAnswers(
            beckAnswers.map((item) => {
                return {
                    question: item.question,
                    values: item.values,
                };
            })
        );
        setIsLoadingLocal(false);
    }, [beckAnswers]);

    function handleSubmit(e) {
        e.preventDefault();
        setIsFetching(true);

        const data = {
            type: "beck",
            answers: userAnswers,
            patient: patientIri,
        };

        tryCreateQuestionnairePatient(data).then((res) => {
            setQuestionnaireId(res.id);
            setScore(parseInt(res.score, 10));
            setIsScoreScreen(true);
            setIsFetching(false);
        });
    }
    return (
        <PageTitle title="Timkl - Points de vigilance">
            <PatientNavLayout patientId={patientId}>
                <div className="informationsList">
                    <div className="page-single-patient">
                        {isLoadingLocal && beckQuestions ? (
                            <DefaultLoader color="#CCC" />
                        ) : (
                            <Animated animationIn="fadeIn">
                                <>
                                    {IsScoreScreen ? (
                                        <BeckScore score={score} questionnaireId={questionnaireId} setIsScoreScreen={setIsScoreScreen} />
                                    ) : (
                                        <>
                                            <div className="d-flex flex-column justify-center align-items-center mx-4">
                                                <Icon name="question" size={48} />
                                                <h3 className="beck_title">Questionnaire abrégé de BECK</h3>
                                                <div className="header_beck">
                                                    <p>
                                                        {" "}
                                                        → Ce questionnaire comporte 13 séries de quatre propositions. Pour chaque série, lisez les quatre
                                                        propositions, puis choisissez celle qui décrit le mieux votre état actuel de ces deux dernières
                                                        semaines. <br />
                                                        L’étendue de l’échelle va de 0 à 39. Plus la note est élevée plus le sujet est déprimé. Les différents
                                                        seuils de gravité retenus par Beck et Beamesderfer :
                                                    </p>
                                                    <div className="d-flex align-items-center mt-1 ">
                                                        <div className="statusDot statusDot--beck statusDot--green mr-1"></div>{" "}
                                                        <p>0 à 3 : pas de dépression </p>
                                                    </div>
                                                    <div className="d-flex align-items-center mt-1">
                                                        <div className="statusDot statusDot--beck statusDot--orange mr-1"></div>{" "}
                                                        <p>4 à 7 : dépression légère </p>
                                                    </div>
                                                    <div className="d-flex align-items-center mt-1">
                                                        <div className="statusDot statusDot--beck statusDot--orange mr-1"></div>{" "}
                                                        <p>8 à 15 : dépression modérée</p>
                                                    </div>
                                                    <div className="d-flex align-items-center mt-1">
                                                        <div className="statusDot statusDot--beck statusDot--red mr-1"></div> <p>16 et + : dépression sévère</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <form onSubmit={handleSubmit}>
                                                <div className="mx-4">
                                                    <BeckForm
                                                        questions={beckQuestions}
                                                        answers={answers}
                                                        userAnswers={userAnswers}
                                                        setUserAnswers={setUserAnswers}
                                                    />
                                                </div>
                                                <div className="d-flex justify-center mt-2">
                                                    <Button
                                                        isLoading={isFetching}
                                                        disabled={userAnswers.length !== beckQuestions.length}
                                                        className="button__primaryButton"
                                                        type="submit"
                                                    >
                                                        Calculer le score{" "}
                                                    </Button>
                                                </div>
                                            </form>
                                        </>
                                    )}
                                </>
                            </Animated>
                        )}
                    </div>
                </div>
            </PatientNavLayout>
        </PageTitle>
    );
};

PatientsNavBeckScreen.propTypes = {
    beckQuestions: PropTypes.arrayOf(PropTypes.object).isRequired,
    beckAnswers: PropTypes.arrayOf(PropTypes.object).isRequired,
    tryFetchBeckQuestions: PropTypes.func.isRequired,
    tryCreateQuestionnairePatient: PropTypes.func.isRequired,
    tryQuestionnairePatient: PropTypes.func.isRequired,
};

const mapStateToProps = ({ questions: { beckQuestions, beckAnswers } }) => ({
    beckQuestions,
    beckAnswers,
});

const mapDispatchToProps = {
    tryFetchBeckQuestions,
    tryCreateQuestionnairePatient,
    tryQuestionnairePatient,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientsNavBeckScreen));
