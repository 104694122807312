import React from "react";
import PropTypes from "prop-types";
import { useParams, withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { tryFetchComplications } from "../../../actions/complications";
import { tryFetchLastDiagnosis, tryFetchConsultationAnswers } from "../../../actions/diagnosis";

import { trySinglePatient } from "../../../actions/patients";
import { DefaultLoader, Icon, SelectDiagnosticComplications, PageTitle, stringIdToId, formatHBA1C } from "@rdcs/dap-front-library";
import PDFConsults from "../../../components/Diagnosis/PDFConsults";
import PatientNavLayout from "../../../components/Patients/PatientNavLayout";
import sortArrayByDueAtAndPosition from "../../../components/Diagnosis/DiagnosisStep4/sortArrayByDueAtAndPosition";
const PatientsNavLastDiagnosticScreen = ({
    diagnosis,
    complications,
    tryFetchLastDiagnosis,
    tryFetchComplications,
    tryFetchConsultationAnswers,
    trySinglePatient,
    user,
    patients,
}) => {
    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);

    const [loading, setLoading] = React.useState(true);
    const [consultationAnswersLoading, setConsultationAnswersLoading] = React.useState(true);

    React.useEffect(() => {
        const promises = [tryFetchLastDiagnosis(patientId), tryFetchComplications(), trySinglePatient(patientId)];

        Promise.all(promises)
            .then(([patientLastDiagnosis]) => {
                setLoading(false);
                return patientLastDiagnosis && tryFetchConsultationAnswers(patientLastDiagnosis.id);
            })
            .then(() => setConsultationAnswersLoading(false));
    }, []);

    const diagnostics = React.useMemo(() => sortArrayByDueAtAndPosition(diagnosis.recos.filter((r) => r.active === true)), [diagnosis.recos]);

    const complicationsDisplaySelected = React.useMemo(
        () => diagnosis.patientLastDiagnosis?.complicationInstances.filter((c) => c.value === true).map((c) => stringIdToId(c.complication)),
        [diagnosis.patientLastDiagnosis?.complicationInstances]
    );

    const complicationsDisplay = React.useMemo(
        () =>
            complicationsDisplaySelected &&
            complications
                .filter((c) => complicationsDisplaySelected.includes(c.id))
                .map((c) => ({
                    id: c.id,
                    name: c.name,
                    description: c.description,
                    parent: c.parent,
                    icon: c.icon,
                })),
        [complications, complicationsDisplaySelected]
    );

    return (
        <PageTitle title="Timkl - Dernière consultation">
            <PatientNavLayout patientId={patientId}>
                {loading ? (
                    <DefaultLoader />
                ) : !diagnosis.patientLastDiagnosis ? (
                    <div>Nous vous invitons à réaliser la première consultation du patient</div>
                ) : (
                    <div className="informationsList">
                        <div className="informationsList__item">
                            <span className="informationsList__item-sourceLink">
                                <Icon name="puce-reco" />
                            </span>
                            Type de diabète : {diagnosis.patientLastDiagnosis.pathology}
                        </div>
                        <div className="informationsList__item">
                            <span className="informationsList__item-sourceLink">
                                <Icon name="puce-reco" />
                            </span>
                            Objectif : {formatHBA1C(diagnosis.patientLastDiagnosis.goal)}%
                            {diagnosis?.patientLastDiagnosis?.goalJustification && (
                                <div className="mt-2">
                                    <p>{diagnosis.patientLastDiagnosis.goalJustification}</p>
                                </div>
                            )}
                        </div>
                        <div className="informationsList__item">
                            <span className="informationsList__item-sourceLink">
                                <Icon name="puce-reco" />
                            </span>
                            <div>Complications :</div>
                            <SelectDiagnosticComplications
                                complications={complicationsDisplay}
                                defaultSelected={complicationsDisplaySelected}
                                disabled={true}
                            />
                        </div>
                        {consultationAnswersLoading ? (
                            <DefaultLoader />
                        ) : diagnosis.patientLastDiagnosis.consultationAnswers.length ? (
                            <div className="informationsList__item">
                                <span className="informationsList__item-sourceLink">
                                    <Icon name="puce-reco" />
                                </span>
                                Questions de la dernière consultation :
                                {diagnosis.patientLastDiagnosis.consultationAnswers.map((question, i) => (
                                    <div key={i} className="mt-2 mb-2 informationsList__item-title">
                                        <p>{question.question}</p>
                                        <p className="mt-1 ml-1 informationsList__item-description">{question.values.join(", ")}</p>
                                    </div>
                                ))}
                            </div>
                        ) : null}
                        <div>
                            <PDFConsults diagnostics={diagnostics} patient={patients.singlePatient} hcp={user} nbOfMonths={3} />
                            <PDFConsults diagnostics={diagnostics} patient={patients.singlePatient} hcp={user} nbOfMonths={12} />
                        </div>
                    </div>
                )}
            </PatientNavLayout>
        </PageTitle>
    );
};

PatientsNavLastDiagnosticScreen.propTypes = {
    diagnosis: PropTypes.object.isRequired,
    complications: PropTypes.arrayOf(PropTypes.object).isRequired,
    tryFetchLastDiagnosis: PropTypes.func.isRequired,
    tryFetchComplications: PropTypes.func.isRequired,
    tryFetchConsultationAnswers: PropTypes.func.isRequired,
    trySinglePatient: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    patients: PropTypes.object.isRequired,
};

const mapStateToProps = ({ auth: { user }, diagnosis, patients, complications: { list } }) => ({
    user,
    diagnosis,
    patients,
    complications: list,
});

const mapDispatchToProps = {
    tryFetchLastDiagnosis,
    tryFetchComplications,
    tryFetchConsultationAnswers,
    trySinglePatient,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientsNavLastDiagnosticScreen));
