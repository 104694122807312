import React from "react";
import PropTypes from "prop-types";
import { Link, useHistory, withRouter, useParams } from "react-router-dom";

import { connect } from "react-redux";
import { tryCreatePatient, tryFetchPotentialPatient, tryMedicalPatient, tryMedicalPatientCreate } from "../../../actions/patients";
import { tryGigyaSearchBySsn } from "../../../actions/auth";
import { userCan } from "../../../reducers/auth";

import { InputNumber, Button, InputCheckbox, PageTitle, Form } from "@rdcs/dap-front-library";
import { Animated } from "react-animated-css";

import PatientsAddForm from "../../../components/Patients/PatientsAddForm";
import PatientSearchItem from "../../../components/Patients/PatientSearchItem";
import AppLayout from "../../../components/Layout/AppLayout";

import CredentialsDataUsage from "./CredentialsDataUsage";

const breadcrumbs = [{ label: "Mes patients", link: "/mes-patients" }, { label: "Ajouter patient" }];

const PatientsAddScreen = ({ auth, patients, tryMedicalPatient, tryFetchPotentialPatient, tryCreatePatient, tryMedicalPatientCreate, tryGigyaSearchBySsn }) => {
    const { id } = useParams();
    const history = useHistory();

    React.useEffect(() => {
        if (id) {
            tryFetchPotentialPatient(id);
        }
    }, [id]);

    const [searchFetched, setSearchFetched] = React.useState(false);
    const [searchSsn, setSearchSsn] = React.useState("");
    const [disabled, setDisabled] = React.useState(true);

    const redirect = (id) => {
        history.push({
            pathname: `/mes-patients/${id}${userCan(["ROLE_NURSE", "ROLE_PHARMACIST"]) ? "" : "/nouvelle-consultation"}`,
            medicalPatientsUpdated: true,
        });
    };

    const addPatient = (patient) =>
        tryMedicalPatientCreate(patient)
            .then(({ patient: { id } }) => redirect(id))
            .catch(console.error);

    const search = () => {
        if (auth.searchBySsnLoading) {
            return;
        }

        setSearchFetched(false);
        tryGigyaSearchBySsn(searchSsn)
            .then(
                (searchBySsnResult) =>
                    searchBySsnResult?.dap?.id && patients.medicalPatient?.id !== searchBySsnResult.dap.id && tryMedicalPatient(searchBySsnResult.dap.id)
            )
            .finally(() => setSearchFetched(true));
    };

    const patientStatus = React.useMemo(() => {
        switch (true) {
            case patients.medicalPatient instanceof Object:
                return "enabled";
            default:
                return "none";
        }
    }, [patients.medicalPatient, auth.searchBySsnResult?.dap]);

    return (
        <PageTitle title="Timkl - Ajouter un patient">
            <AppLayout navActive={0} breadcrumbs={breadcrumbs}>
                <div className="grid">
                    <main className="col-sm-offset-10 col-sm-80">
                        <h1 className="title-1">Ajouter un nouveau patient</h1>
                        <Form onSubmit={search}>
                            <InputNumber
                                label="Numéro de sécurité sociale du patient"
                                legend="Saisissez le numéro de 13 chiffres"
                                name="ssn"
                                length={13}
                                onChange={setSearchSsn}
                                requiredNotInLabel
                                required
                            />
                            <CredentialsDataUsage />
                            <div className="form__group text-center">
                                <InputCheckbox
                                    label="En validant la création de dossier, je confirme avoir informé mon patient qui accepte l’accès aux données concernant sa santé (recommandations, nombre de mesures de glycémies, informations de suivi) et le suivi de son parcours de soin (y compris par contact téléphonique ou e-mail) sur la plateforme par l’infirmière coordinatrice Timkl et son équipe."
                                    onChange={(val) => setDisabled(!val)}
                                />
                                <Link to="/mes-patients" className="button button__secondaryButton">
                                    Retour
                                </Link>
                                <Button className="button__primaryButton" disabled={disabled} isLoading={auth.searchBySsnLoading}>
                                    Ajouter le patient
                                </Button>
                            </div>
                        </Form>

                        {searchFetched && (
                            <div>
                                {auth.searchBySsnResult && auth.searchBySsnResult.dap ? (
                                    <div className="blockList mt-4">
                                        <PatientSearchItem
                                            key={auth.searchBySsnResult.dap.id}
                                            item={auth.searchBySsnResult.dap}
                                            patientStatus={patientStatus}
                                            addPatient={addPatient}
                                            addPatientLoading={patients.patientAddLoading}
                                        />
                                    </div>
                                ) : (
                                    <Animated animationIn="fadeIn">
                                        {auth.searchBySsnResult && auth.searchBySsnResult.gigya ? (
                                            <div className="bgSuccess radius shadow mt-4 mb-2 pt-2 pl-3 pb-2 pr-3">
                                                Nous vous invitons à compléter le profil du patient
                                            </div>
                                        ) : (
                                            <div className="bgWarning radius shadow mt-4 mb-2 pt-2 pl-3 pb-2 pr-3">
                                                Aucun résultat, nous vous invitons à créer le profil du patient
                                            </div>
                                        )}
                                        <div className="blockItem">
                                            <PatientsAddForm
                                                patient={patients?.potentialPatient}
                                                gigyaProfile={auth.searchBySsnResult?.gigya}
                                                violations={patients.patientCreateError.violations || {}}
                                                loading={patients.patientCreateLoading}
                                                defaultSsn={searchSsn}
                                                submit={(data) =>
                                                    tryCreatePatient({ ...data, potentialPatient: patients?.potentialPatient["@id"] || null })
                                                        .then(({ id }) => redirect(id))
                                                        .catch(console.error)
                                                }
                                            />
                                        </div>
                                    </Animated>
                                )}
                            </div>
                        )}
                    </main>
                </div>
            </AppLayout>
        </PageTitle>
    );
};

PatientsAddScreen.propTypes = {
    auth: PropTypes.object.isRequired,
    patients: PropTypes.object.isRequired,
    tryMedicalPatient: PropTypes.func.isRequired,
    tryCreatePatient: PropTypes.func.isRequired,
    tryFetchPotentialPatient: PropTypes.func.isRequired,
    tryMedicalPatientCreate: PropTypes.func.isRequired,
    tryGigyaSearchBySsn: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, patients }) => ({ auth, patients });

const mapDispatchToProps = {
    tryMedicalPatient,
    tryCreatePatient,
    tryFetchPotentialPatient,
    tryMedicalPatientCreate,
    tryGigyaSearchBySsn,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientsAddScreen));
