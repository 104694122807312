import React from "react";
import PropTypes from 'prop-types';
import { useParams, withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { tryEditPatient, tryMedicalPatientDelete } from "../../../actions/patients";

import { Animated } from "react-animated-css";
import { Icon, PageTitle, getViolations } from "@rdcs/dap-front-library";

import PatientNavLayout from "../../../components/Patients/PatientNavLayout";
import PatientCoordonneesForm from "../../../components/Patients/PatientCoordonneesForm";
import PatientCard from "../../../components/Patients/PatientCard";

const PatientsNavCoordonneesScreen = ({
    patients,
    tryEditPatient,
    tryMedicalPatientDelete
}) => {
    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);
    const [medicalPatient, setMedicalPatient] = React.useState(null);

    const onRemovePatient = React.useCallback(() =>
        tryMedicalPatientDelete(medicalPatient)
        .then(() => {
            history.push("/mes-patients");
        })
    , [medicalPatient]);

    return (
        <PageTitle title='Timkl - Coordonnées patient'>
            <PatientNavLayout patientId={patientId} onLoad={setMedicalPatient} onMedicalPatientUpdate={setMedicalPatient}>
                <div className="informationsList">
                    <div className="page-single-patient">
                        {medicalPatient &&
                        <Animated animationIn="fadeIn">
                            <PatientCard
                                patient={medicalPatient.patient}
                                onRemovePatient={onRemovePatient}
                            />
                            <div className="blockItem">
                                <PatientCoordonneesForm
                                    submit={patient =>
                                        tryEditPatient(patientId, patient)
                                    }
                                    profile={medicalPatient.patient}
                                    patientId={patientId}
                                    profileEditLoading={patients.patientEditLoading}
                                    profileEditError={patients.patientEditError}
                                    violations={getViolations(patients.patientEditError)}
                                />
                            </div>
                        </Animated>}
                    </div>
                </div>
            </PatientNavLayout>
        </PageTitle>
    );
};

PatientsNavCoordonneesScreen.propTypes = {
    patients: PropTypes.object.isRequired,
    tryEditPatient: PropTypes.func.isRequired,
    tryMedicalPatientDelete: PropTypes.func.isRequired,
};

const mapStateToProps = ({ patients }) => ({ patients });

const mapDispatchToProps = {
    tryEditPatient,
    tryMedicalPatientDelete
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientsNavCoordonneesScreen));
