import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams, withRouter, useHistory } from "react-router-dom";

import { Gauge, Button, Icon } from "@rdcs/dap-front-library";
import { Animated } from "react-animated-css";
import LeftImage from "@rdcs/dap-front-library/static/images/illu_pharma.svg";
import { tryDeleteQuestionnairePatient } from "../../actions/questionnaire";

const EpicesScore = ({ score, questionnaireId, setIsScoreScreen, tryDeleteQuestionnairePatient }) => {
    const history = useHistory();

    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);

    function handleClickDelete() {
        tryDeleteQuestionnairePatient(questionnaireId).then(() => {
            setIsScoreScreen(false);
        });
    }

    function handleClickGoNext() {
        history.push(`/mes-patients/${patientId}/dashboard`);
    }

    return (
        <Animated animationIn="fadeIn">
            <h1 className="txtSizeBig mb-4 mt-4 score_beck_epices_title">Score questionnaire EPICES</h1>
            <div className="epices-becks-score">
                <div className="epices-becks-score--leftImage">
                    <img src={LeftImage} />
                </div>
                <div>
                    <div className="epices-becks-score--rightForm">
                        <div>
                            <p className="mb-4 epices-becks-score-subtitle ">
                                {score > 30
                                    ? "Au vu des réponses, le patient se situe au dessus du seuil de précarité"
                                    : "Au vu des réponses, le patient se situe en dessous du seuil de précarité"}
                            </p>
                            <Gauge
                                legend="Seuil de précarité"
                                classNameLegend="epicesLegend"
                                className="gauge_epices"
                                epices
                                value={score > 40 ? 40 : Math.round(score)}
                                info="Score du patient"
                                min={0}
                                max={40}
                            />
                        </div>

                        <section className="mt-4 right d-flex">
                            <Button onClick={handleClickDelete} className=" button__secondaryButton d-flex align-items-center">
                                Ne pas enregistrer ce questionnaire
                                <Icon name="arrow-right" size={15} />
                            </Button>
                            <Button onClick={handleClickGoNext} className=" button__primaryButton d-flex align-items-center">
                                Revenir au profil patient
                                <Icon name="arrow-right" size={15} />
                            </Button>
                        </section>
                    </div>
                </div>
            </div>
        </Animated>
    );
};

EpicesScore.propTypes = {
    score: PropTypes.number.isRequired,
    questionnaireId: PropTypes.number.isRequired,
    setIsScoreScreen: PropTypes.func.isRequired,
    tryDeleteQuestionnairePatient: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    tryDeleteQuestionnairePatient,
};

const mapStateToProps = ({ reports, auth: { user } }) => ({
    reports,
    user,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EpicesScore));
