import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { connect } from "react-redux";

import { PageTitle, SliderCards, Modal } from "@rdcs/dap-front-library";

import PatientReportForm from "../../../../components/Patients/PatientReportForm/index";

import moment from "moment";

import PatientNavLayout from "../../../../components/Patients/PatientNavLayout";
import SubTitle from "../../../../components/Patients/SubTitle";

import AppointmentRealizedCardContent from "./AppointmentRealizedCardContent";

import RecommendedVisits from "./sections/RecommendedVisits";
import RecommendedExams from "./sections/RecommendedExams";
import Notes from "./sections/Notes";

const PatientsNavDoctorDashboardScreen = ({ diagnosis, patients, reports }) => {
    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);
    const patientIri = React.useMemo(() => `/patients/${patientId}`, [patientId]);
    const [pageIsLoaded, setPageIsLoaded] = React.useState(false);

    /* VISITS */
    // Retrieve all consultation's appointments, including those not linked to a recommendation.
    const consultAppointments = React.useMemo(
        () =>
            patients.patientAppointments.filter(
                ({ recommendations }) => recommendations.length === 0 || recommendations.find(({ type }) => type === "consultation")
            ),
        [patients.patientAppointments]
    );

    // Retrieve all consultation's appointments linked to a recommendation.
    const consultAppointmentsWithRecommendation = React.useMemo(
        () => patients.patientAppointments.filter(({ recommendations }) => recommendations.find(({ type }) => type === "consultation")),
        [patients.patientAppointments]
    );

    // Retrieve all examination's appointments.
    const examenAppointments = React.useMemo(
        () => patients.patientAppointments.filter(({ recommendations }) => recommendations.find(({ type }) => type === "examination")),
        [patients.patientAppointments]
    );

    // Retrieve all consultation's appointments done.
    const appointmentsDone = React.useMemo(
        () => consultAppointments.filter(({ doneAt }) => doneAt !== null).sort((a, b) => new Date(b.doneAt) - new Date(a.doneAt)),
        [consultAppointments]
    );

    // Retrieve all consultation's recommendations.
    const recoConsults = React.useMemo(() => {
        const now = moment().unix();

        return (
            diagnosis.recos
                // Keep consultations only
                .filter(({ recommendation: { type } }) => type === "consultation")
                // Bind Diagnostic recos with appointments
                .map((reco) => {
                    for (const consultAppointment of consultAppointmentsWithRecommendation) {
                        if (consultAppointment.recommendations.find((recommendation) => recommendation.id === reco.recommendation.id)) {
                            return {
                                ...reco,
                                statusColor: "green",
                                appointment: consultAppointment,
                            };
                        }
                    }
                    return {
                        ...reco,
                        statusColor: now < moment(reco.dueAt).unix() ? "orange" : "red",
                    };
                })
                // Remove all consultation done.
                .filter(({ appointment = null }) => !appointment?.doneAt)
                // Sort by color and after, sort by dueAt
                .sort((a, b) => {
                    const codeA = a.statusColor.charCodeAt(0);
                    const codeB = b.statusColor.charCodeAt(0);

                    if (codeA !== codeB) {
                        return codeB - codeA;
                    }
                    return new Date(a.appointment?.date || a.dueAt) - new Date(b.appointment?.date || b.dueAt);
                })
        );
    }, [diagnosis.recos, consultAppointmentsWithRecommendation]);

    /* EXAMENS */
    const recoExamens = React.useMemo(
        () =>
            diagnosis.recos
                // Keep consultations only
                .filter(({ recommendation: { type } }) => type === "examination")
                // Bind Diagnostic recos with appointments
                .map((reco) => {
                    for (const examenAppointment of examenAppointments) {
                        if (examenAppointment.recommendations.find((recommendation) => recommendation.id === reco.recommendation.id)) {
                            return {
                                ...reco,
                                appointment: examenAppointment,
                            };
                        }
                    }
                    return reco;
                })
                .filter(({ appointment = null }) => !appointment?.doneAt)
                .sort((a, b) => new Date(a.appointment?.date || a.dueAt) - new Date(b.appointment?.date || b.dueAt))
                .slice(0, 8),
        [diagnosis.recos, examenAppointments]
    );

    function combineAndSortByDate(list1, list2) {
        // Combine the two lists into a single array
        let combinedList = [...list1, ...list2];

        // Sort the combined list by date in descending order
        combinedList.sort((a, b) => {
            let dateA = a.doneAt || a.createdAt;
            let dateB = b.doneAt || b.createdAt;
            return new Date(dateB) - new Date(dateA);
        });

        return combinedList;
    }

    return (
        <PageTitle title="Timkl - Mes Patients">
            <PatientNavLayout
                patientId={patientId}
                onLoad={() => {
                    setPageIsLoaded(true);
                }}
            >
                {/* Visites réalisées */}
                <SliderCards
                    icon="visite-realisees"
                    title="Visites réalisées"
                    subTitle={<SubTitle>Visites réalisées par le patient ces 12 derniers mois.</SubTitle>}
                    renderCard={(report) => {
                        const card = (
                            <AppointmentRealizedCardContent
                                type={report["@type"]}
                                title={report.topic}
                                date={report.doneAt}
                                createdAt={report.createdAt}
                                comment={report.comment}
                            />
                        );

                        return report["@type"].toLowerCase() === "report" ? (
                            <Modal
                                reportModal
                                buttonClass="complicationCategory__row"
                                containerClass="report__modal__container"
                                className="report__modal__content"
                                closeButtonClass="closeModalLarge"
                                buttonContent={card}
                            >
                                <PatientReportForm
                                    title="Lancer un entretien de suivi"
                                    patientId={report.patient["@id"]}
                                    report={report}
                                    edition
                                    canModify={false}
                                />
                            </Modal>
                        ) : (
                            card
                        );
                    }}
                    itemsCard={combineAndSortByDate(appointmentsDone, reports.list)}
                    renderEmpty={<p className="mt-2">Aucune visite réalisée</p>}
                    className="mb-4"
                    classNameCard="d-flex justify-space-between"
                    withFog
                />
                {/* Visites recommandées*/}
                <RecommendedVisits isLoading={diagnosis.recosLoading || !pageIsLoaded} datas={recoConsults} patientId={patientId} patientIri={patientIri} />
                {/* Examens recommandés */}
                <RecommendedExams
                    isLoading={(diagnosis.recosLoading && patients.patientAppointmentsLoading) || !pageIsLoaded}
                    datas={recoExamens}
                    patientId={patientId}
                    patientIri={patientIri}
                />
                {/* Notes */}
                <Notes patientId={patientId} />
            </PatientNavLayout>
        </PageTitle>
    );
};

PatientsNavDoctorDashboardScreen.propTypes = {
    diagnosis: PropTypes.object.isRequired,
    patients: PropTypes.object.isRequired,
    reports: PropTypes.object.isRequired,
};

const mapStateToProps = ({ diagnosis, patients, reports }) => ({
    diagnosis,
    patients,
    reports,
});

export default connect(mapStateToProps)(PatientsNavDoctorDashboardScreen);
